@import "flyer-teaser-variables";
@import "../spacing/spacing-factory";
@import "../color/color-variables";
@import "../positioning/positioning";
@import "../grid/grid-factory";

@mixin flyer-teaser-component() {
    .flyer {
        position: relative;
        display: block;
        background-color: $ui-color-white;
        border: none;
        @include margin-bottom(spacing-7);
        @include margin-top(spacing-5);

        &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            content: " ";
            transition: opacity 0.3s ease;

            @include top(spacing-0);
            @include left(spacing-0);
        }

        &:hover,
        &:active {
            color: $flyer-text-color;
        }

        &__inner-wrapper > * {
            width: 100%;
        }

        &__image {
            width: 100%;
        }
    }

    @include breakpoint($M) {
        .flyer {
            @include margin-top(spacing-0);
        }
    }

    @include breakpoint($L) {
        .flyer {
            @include margin-top(spacing-0);
        }
    }
}
