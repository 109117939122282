.create-giftcard {
    &__input {
        position: relative;
        margin: auto;
    }

    &__image {
        cursor: pointer;
    }
}
