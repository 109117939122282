@import "../../../../styles/styleguide/color/color-variables";

.sold-out-stamp {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.52);
    text-align: center;

    &__stamp {
        background-color: white;
        border: 4px solid $danger;
        color: $danger;
        font-size: 1.7em;
        font-weight: 800;
        padding: 10px;
        text-transform: uppercase;
    }

    &__stampHolder {
        transform: rotate(-45deg);
    }
}
