@import "../../../../../styles/styleguide/color/color-variables";
@import "../../../../../styles/styleguide/font/font-variables";

.delivery-block {
    width: 100%;
    background: $ui-color-white;
    border: 1px solid $ui-color-input-grey;
    border-radius: 0.1em;

    > div {
        padding: 1rem 1rem 1rem 2rem;
    }

    > div:not(:last-child) {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
}
