.energyLabel-large {
    max-width: 100%;
    height: auto;
    max-height: 500px;
}

.energyLabel-small {
    max-width: 70px;
    height: auto;

    &:hover {
        cursor: pointer;
    }
}

.energyLabel-noLargeLabel {
    &:hover {
        cursor: default !important;
    }
}
