@import "../../../styles/styleguide/grid/grid-factory";
@import "../../../styles/styleguide/color/color-variables";

.e-commerce {
    background: $ui-color-grey;
    border: 1px solid $ui-color-input-grey;
    border-radius: 0.25em;
    margin-left: -1.4%;
    margin-right: -1.4%;
    border-left: none;
    border-right: none;
    position: relative;

    input {
        text-align: center;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        appearance: textfield;
        -moz-appearance: textfield;
    }
}

.e-commerce__quick-actions {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 380;
    background-color: $ui-color-white;

    &--hidden {
        display: none;
    }

    &--has-comparison .btn {
        width: 50% !important;
        font-size: 1rem;
    }
}

.e-commerce__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
        margin-bottom: 0;
    }
}

@include breakpoint($M) {
    .e-commerce__quick-actions {
        display: none;
    }

    .e-commerce {
        border: 1px solid $ui-color-input-grey;
        margin-left: 0;
        margin-right: 0;
    }
}
