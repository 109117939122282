.storeGoogleMaps {
    &:hover {
        text-decoration: none;
    }
}

.storeGoogleMaps-link {
    text-decoration: underline;
    display: block;
}

.storeGoogleMaps-container {
    height: 700px;
}
