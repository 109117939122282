$css-grid-columns-to-generate: 1, 2, 3, 4, 5, 6;

@each $col in $css-grid-columns-to-generate {
    .grid-col-start-#{$col} {
        grid-column-start: $col;
    }

    @each $span in $css-grid-columns-to-generate {
        .grid-col-start-#{$col}-span-#{$span} {
            grid-column-start: $col;
            grid-column-end: $col + $span;
        }
    }
}
