@import "../../../../../styles/styleguide/color/color-variables";

.profileAddressBook {
    &__address {
        border: $ui-color-divider solid 1px;

        p {
            margin-bottom: 0;
        }
    }
}
