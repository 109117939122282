@use "sass:map";

@import "/lib/styles/styleguide/grid/grid-factory";
@import "/lib/styles/styleguide/spacing/spacing-factory";

.card-deck {
    display: flex;
    flex-direction: column;

    @media (min-width: #{map.get($grid-breakpoints, sm)}) {
        flex-wrap: nowrap;
    }

    > div {
        flex: 1;

        .hrc {
            height: 100%;

            > div {
                height: 100%;

                .card {
                    height: 100%;
                }
            }
        }
    }

    &:first-child::after {
        content: "";
        @include margin-bottom(spacing-6);
    }
}

@include breakpoint($M) {
    .card-deck {
        flex-direction: row;
    }
}
