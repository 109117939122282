@import "/lib/styles/styleguide/color/color-variables";

.recentSearchesItem {
    &__delete {
        color: $ui-color-primary-black;
    }
}

.recentSearchesFooter {
    button {
        &:hover {
            div {
                text-decoration: underline;
            }
        }
    }
}
