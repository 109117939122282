@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/layer/layer-factory";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    pointer-events: none;

    @include z-index(overlay);
    &__header {
        @include z-index(modal);
    }

    &--open {
        pointer-events: auto;
        animation: fade-in 200ms forwards ease-out;
    }

    @keyframes fade-in {
        0% {
            background-color: transparent;
        }
        100% {
            background-color: $ui-color-overlay-black;
        }
    }
}
