@use "sass:map";

@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/grid/_grid-factory";

.register-form__card-header {
    padding: 0 1.25rem;
    line-height: 1.4;
}

.register-form__card-body {
    @media (max-width: #{map.get($grid-breakpoints, sm)}) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.register-form__card-footer {
    padding-bottom: 20px;

    .disclaimer {
        font-weight: 300;
        font-size: 14px;
    }
}
