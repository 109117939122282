@use "sass:map";

@import "/lib/styles/styleguide/color/_color-variables";
@import "/lib/styles/styleguide/grid/grid-variables";

.steps-bar {
    &__list {
        margin-bottom: 0;
    }

    &__item {
        text-align: center;
        height: 50px;
        line-height: 2em;
        position: relative;
        padding: 10px 0;
        margin-right: 22px;
        background-color: $ui-gray-05;
        color: $text-color;
        font-weight: 500;

        &::before,
        &::after {
            content: "";
            position: absolute;
            height: 100%;
            top: 0;
            bottom: 0;
        }

        &::before {
            border-top: 25px solid $ui-gray-05;
            border-bottom: 25px solid $ui-gray-05;
            border-left: 18px solid transparent;
            left: -18px;
        }

        &::after {
            right: -18px;
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent;
            border-left: 18px solid $ui-gray-05;
        }

        &:first-child {
            &::before {
                display: none;
            }
        }

        &:last-child {
            display: none;
            &::after {
                border-top-color: $ui-gray-05;
                border-bottom-color: $ui-gray-05;
            }

            @media (min-width: #{map.get($grid-breakpoints, md)}) {
                &:last-child {
                    display: block !important;
                }
            }
        }

        a {
            color: white;
            text-decoration: none;
        }

        &--active {
            background-color: $information;
            color: white;

            &::before {
                border-top-color: $information;
                border-bottom-color: $information;
            }

            &::after {
                border-left-color: $information;
            }

            &:last-child {
                &::after {
                    border-top-color: $information;
                    border-bottom-color: $information;
                }
            }

            &:nth-last-child(1 of &) {
                background-color: $primary;
                color: white;

                &::before {
                    border-top-color: $primary;
                    border-bottom-color: $primary;
                }

                &::after {
                    border-left-color: $primary;
                }

                &:last-child {
                    &::after {
                        border-top-color: $primary;
                        border-bottom-color: $primary;
                    }
                }

                .steps-bar__item--passed {
                    display: none;
                }
            }
        }
    }
}
