@import "/lib/styles/styleguide/color/_color-variables";
@import "/lib/styles/styleguide/grid/_grid-factory";
@import "../productComparison.variables.scss";

.product-comparison {
    &__actions {
        position: absolute;

        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        width: 100%;
        top: unset;

        &__print {
            padding: 0;
            text-align: left;
        }

        &__difference-only {
            margin: 0;

            input {
                position: relative;
                margin: 0 0.8rem 0 0.25rem;
            }
        }

        &__wrapper {
            height: $actions__height;
        }
    }
}
