@use "sass:map";

@import "/lib/styles/styleguide/grid/grid-factory";
@import "/lib/styles/styleguide/spacing/_spacing-factory";
@import "/lib/styles/styleguide/color/color-variables";

.cart-item {
    background-color: $ui-color-white;

    &__loading {
        &::before {
            animation: article-loading 1.4s infinite;
            background: #e0e3eb;
            content: "";
            display: block;
            height: 100%;
            opacity: 0.3;
            left: 0;
            padding: 0;
            position: absolute;
            top: 0;
            width: 1rem;
        }
    }

    &__total-price {
        padding-left: 0;
    }

    &__recupel {
        position: absolute;
        font-size: 0.6rem;
        white-space: nowrap;
        bottom: -50%;
        right: 0;
        font-weight: normal;
    }

    &__product-title {
        h5 {
            margin: 0 !important;
            padding: 0 !important;
            height: auto !important;
        }
    }

    &__actions {
        background-color: $ui-color-white;
        color: $primary;
        font-size: 0.8em;
    }

    &__delivery-text {
        color: $success;
        font-weight: bold;
    }

    &__divider {
        display: block;
        height: 1px;
        width: 100%;
        background-color: $ui-color-divider;
    }

    &__error {
        border-left: 3px solid $danger;
    }

    &__warning {
        border-left: 3px solid $warning;
    }

    &__price {
        font-size: large;
        position: relative;

        @include margin-top(spacing-4);

        @media (min-width: 375px) {
            margin-top: 0;
        }

        @media (min-width: #{map.get($grid-breakpoints, sm)}) {
            font-size: larger;
        }
    }
}

@keyframes article-loading {
    0% {
        left: 0;
        width: 0;
    }

    50% {
        left: 0;
        width: 100%;
    }

    100% {
        left: 100%;
        width: 0;
    }
}
