@import "../../../styles/styleguide/utils";

.storePicker-layout {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    &--footer,
    &--header {
        flex: 0 1 auto;

        p {
            @include smallHeightDevice {
                margin-bottom: 0.1rem;
            }
        }
    }

    &--content {
        flex: 1 1 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        @include scrollBarStyling;
    }
}
