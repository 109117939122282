@use "sass:color";
@import "../../../styles/styleguide/grid/_grid-factory";
@import "../../../styles/styleguide/vendor-overrides/bootstrap/custom";
@import "bootstrap/scss/vendor/rfs";
@import "../../../styles/styleguide/color/color-variables";

.box-counter {

	.tick-credits {
		display: none;
	}

	&-sm {
		@include font-size($h4-font-size * 1.5);
	}

	&-md {
		@include font-size($h3-font-size * 1.87);
	}

	&-lg {
		@include font-size($h2-font-size * 2.5);
	}

	&-xl {
		@include font-size($h1-font-size * 4);
	}

	&--light {
		.tick-flip-panel {
			color: $ui-color-primary-black;
			background-color: color.adjust($ui-color-light-grey, $lightness: 5%);
		}

		.tick-flip-panel-back-shadow {
			background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
		}

		.tick-flip-panel-front-shadow {
			background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
		}

		.tick-flip-panel-back:after {
			background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0.05) 0, transparent 30%);
		}
	}
}