@import "../../styles/styleguide/color/color-variables";

.brand-overview {
    & h3 {
        color: $ui-color-darkgrey;
    }

    & a:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }

    &__image-wrapper {
        padding-top: 80%;
        position: relative;
        border: 1px solid $ui-color-light-grey;
        border-radius: 2px;
        overflow: hidden;
        -webkit-transition: opacity 0.22s ease-in-out;
        transition: opacity 0.22s ease-in-out;

        &:hover {
            background-color: $ui-color-white;
            opacity: 0.8;
            border-radius: 2px;
            overflow: hidden;
            -webkit-transition: opacity 0.22s ease-in-out;
            transition: opacity 0.22s ease-in-out;
        }
    }

    &__image {
        margin: auto;
        text-align: center;
        position: absolute;
        max-width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
