.description-component {
    &__content {
        &.ddd-truncated {
            max-height: 150px;
        }

        height: auto;
        max-height: 150000px;
        transition: all 0.5s ease-in-out;
        overflow: hidden;
    }
}
