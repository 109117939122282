.btn {
    &--loading {
        position: relative;

        &::before {
            animation: btn-loading 1.4s infinite;
            background: $primary;
            content: "";
            display: block;
            height: 100%;
            opacity: 0.3;
            left: 0;
            padding: 0;
            position: absolute;
            top: 0;
            width: 1rem;
        }
    }

    @each $color, $value in $theme-colors {
        &-#{$color}:disabled {
            background: rgba($value, 0.65);
            border-color: rgba($value, 0.65);
        }

        &-#{$color}.btn--loading:before {
            background: $value;
        }
    }
}

.btn-link{
    &:focus {
        box-shadow: none;
    }
}

@keyframes btn-loading {
    0% {
        left: 0;
        width: 0;
    }
    50% {
        left: 0;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 0;
    }
}
