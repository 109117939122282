@import "../../../../../styles/styleguide/spacing/spacing-factory";

.recupel-included {
    text-align: right;

    p {
        width: 100%;
        margin-bottom: 0;
        font-size: 0.7em;
    }
}
