@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/grid/grid";

.autocompleteItem {
    cursor: pointer;
    padding: 0.5rem;

    &--active, &:hover {
        background-color: $ui-gray-06;
    }
}
