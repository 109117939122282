@use "sass:map";

@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/components/Navigation/navigation-component-variables";
@import "/lib/styles/styleguide/grid/grid-factory";

$asideFlyout-maxWidth: 400px;
$asideFlyout-controlsRibbon-height: 55px;

.asideFlyout {
    top: 0;

    &__flyout {
        height: 100%;
        pointer-events: all;
        left: 0;
        transform: translateX(-100%);
        transition: transform 200ms ease-out;
        background-color: $ui-color-white !important;

        @media (min-width: #{map.get($grid-breakpoints, sm)}) {
            max-width: $asideFlyout-maxWidth;
        }
    }

    &__content {
        flex-grow: 1;
    }

    &__controls {
        bottom: 0;
        height: $asideFlyout-controlsRibbon-height;
        border-top: 1px solid $ui-color-light-grey;

        button:first-letter {
            text-transform: uppercase;
        }
    }

    &--open {
        .asideFlyout {
            &__flyout {
                transform: translateX(0);
            }
        }
    }
}
