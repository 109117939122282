//###################################
//####                           ####
//####    Overrides variables    ####
//####                           ####
//###################################
@import "./../../grid/_grid-factory";
.iiz {
    height: fit-content;
    max-height: 50vh;

    @media (max-width: 1024px) {
        &__zoom {
            &-portal {
                background-color: black;
            }
        }
    }
}
