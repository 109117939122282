@use "sass:map";

@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/grid/grid-factory";

.delivery-amount {
    background-color: $ui-color-transparent;
}

.delivery-amount--disabled {
    background: $ui-color-light-grey !important;
    pointer-events: none;
}

.delivery-amount__inputgroup-addon {
    span {
        background-color: $ui-color-white;
        color: $text-color !important;

        &:hover {
            cursor: pointer;
            background-color: $ui-color-grey !important;
        }
    }
}

.delivery-amount__dropdown {
    background-color: $ui-color-white !important;
    color: $text-color !important;
    border: 1px solid $ui-color-input-grey;
    display: flex;
    width: 9.5rem;
    justify-content: space-between;
    align-items: center;
    text-indent: 0.75rem;

    @media (min-width: #{map.get($grid-breakpoints, md)}) {
        width: 8.25rem;
    }
}

.delivery-amount__dropdownMenu {
    max-height: 275px;
    min-width: 9.5rem !important;
    overflow: auto;

    @media (min-width: #{map.get($grid-breakpoints, md)}) {
        min-width: 8.25rem !important;
    }
}

.delivery-amount__inputgroup {
    width: 9.5rem;

    @media (min-width: #{map.get($grid-breakpoints, md)}) {
        width: 8.25rem;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
        text-align: center;
    }
}

@include breakpoint($M) {
    .delivery-amount__inputgroup {
        min-width: 8.25em;
        max-width: 8.25em;
    }
}
