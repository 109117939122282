@import "../../styles/styleguide/color/color-variables";

$language-selector__item--border-color: $opacities-dark-ui-copy-grey !default;
$language-selector__item--color: $opacities-dark-ui-copy-grey !default;
$language-selector__item--color--active: $opacities-dark-ui-divider-grey !default;
$language-selector__item--color--hover: $opacities-dark-ui-background-grey !default;

.language-selector {
    padding: 0.7rem 0 0.7rem 0.7rem;
    margin: 0;

    text-transform: uppercase;
    display: flex;
    list-style: none;
}

.language-selector__item {
    padding: 0 0.5rem;

    border-right: 0.1rem solid $language-selector__item--border-color;

    color: $language-selector__item--color;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;

        border-right: none;
    }

    &.active {
        color: $language-selector__item--color--active;
    }
}

.language-selector__link {
    text-decoration: none;

    color: $language-selector__item--color;

    &:hover {
        color: $language-selector__item--color--hover;
    }
}
