.shoppingCartSummary {
    &__remove {
        max-width: 0;
        overflow: hidden;
        transition: 0.2s ease-in-out;
    }

    &__article {
        position: relative;

        &:hover {
            .shoppingCartSummary__remove {
                max-width: 50px;
            }
        }

        &--loading {
            &::before {
                animation: article-loading 1.4s infinite;
                background: #e0e3eb;
                content: "";
                display: block;
                height: 100%;
                opacity: 0.3;
                left: 0;
                padding: 0;
                position: absolute;
                top: 0;
                width: 1rem;
            }
        }
    }
}
