@import "../../../../styles/styleguide/color/color-variables";
@import "../../../../styles/styleguide/spacing/_spacing-factory";

.bPostAddressField {
    &__list {
        padding: 0;
        transform: translate3d(0, 39.5px, 0) !important;
    }

    &__item {
        background-clip: padding-box;
        border-bottom: 1px solid $ui-gray-04;
        cursor: pointer;
        display: block;
        white-space: pre-wrap;

        @include padding(spacing-3);

        &--active {
            background-color: $ui-gray-06;
        }

        &:active,
        :focus {
            color: $dark;
            background-color: $ui-gray-06;
        }

        &:last-child {
            border-bottom: none;
        }

        &:hover,
        &.selected {
            background-color: $ui-gray-06;
        }

        &--footer {
            background-color: $ui-gray-06;
            text-decoration: underline;
        }
    }
}
