@import "/lib/styles/styleguide/color/color-variables";

.store-status {
    display: flex;
    margin-bottom: 0.5rem;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        border-radius: 50%;
        height: 10px;
        aspect-ratio: 1;
        left: 0;
        top: calc(50% - 5px);
        background-color: $danger;
    }

    &--open {
        &::before {
            background-color: $success;
        }
    }

    & > span {
        &:first-child {
            margin-left: 20px;
        }

        display: block;
    }

    &__separator {
        height: 1rem;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        border-left: 1px solid $ui-color-input-grey;
    }
}
