@import "../../styles/styleguide/color/color-variables";

.store-locator-mobile {
    background: $ui-color-blue;
    color: white;
    padding: 2em 1em;
}

.store-locator-mobile-submit {
    text-align: center;
    background: $ui-color-light-grey;
    color: $ui-color-primary-black;

    &:hover {
        cursor: pointer;
    }
}
