@import "../../../styles/styleguide/grid/grid-factory";

.title-component {
    &--background {
        background-size: cover;

        @include breakpoint($L) {
            height: 220px;
        }
    }

    &--styles-left {
        text-align: left;
    }

    &--styles-center {
        text-align: center;
    }

    &--styles-right {
        text-align: right;
    }

    .title {
        position: relative;

        &__background-color-element {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        &__content {
            position: relative;
            z-index: 2;
        }
    }
}
