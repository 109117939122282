@import "../../styles/styleguide/spacing/spacing-factory";

.promo-banner-component {
    &__container {
        @include margin-vertical(spacing-3);
    }

    &__experience-fragment {
        .image-component {
            @include margin-bottom(spacing-2);
        }
    }
}
