@import "../../../../styles/styleguide/grid/grid-factory";

.skeleton-product-tile {
    &__title,
    &__description,
    &__image,
    &__feature,
    &__price {
        display: block;
        background: #eee;

        height: 100%;
        min-height: 0.5em;

        width: 100%;
        min-width: 50px;

        border: 0.5em white solid;
    }

    &__price {
        border-width: 0 0.5em 0 0;
    }

    &__image {
        aspect-ratio: 146 / 109;
    }

    &__feature {
        border: none;

        &-list li::before {
            display: none;
        }
    }
}

.product-tile--skeleton {
    display: block;
    margin-right: 3%;

    width: 100%;

    @include breakpoint($M) {
        width: calc(100% / 3);
    }

    @include breakpoint($M) {
        width: calc(100% / 5);
    }
    @include breakpoint($XL) {
        width: calc(100% / 6);
    }
}

.product-tile__list--skeleton {
    display: flex;
    align-content: space-between;
    justify-content: flex-start;
    width: 100%;

    .productSlider-recents & {
        padding: 0 4em;
    }
}
