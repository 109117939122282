@use "sass:map";

@import "../../grid/grid-factory";

@each $color, $cValue in $theme-colors {
    @each $bp, $bpValue in $grid-breakpoints {
        .bg-#{$bp}-#{$color} {
            @media (min-width: map.get($grid-breakpoints, $bp)) {
                background-color: $cValue !important;
            }
        }
    }
}
