.registerCheckoutForm {
    &__checkoutGuest {
        height: 100%;

        > div {
            height: 100%;
        }

        .guestForm {
            height: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
        }
    }

    &__cardHeader {
        padding: 0 1.25rem;
        line-height: 1.4;
        font-size: 1.25rem;
    }

    &__cardFooter {
        padding: 0 1.25rem 1.25rem 1.25rem;
    }
}
