@import "../../../styles/styleguide/color/color-variables";

.flyout-button {
    align-self: center;
    position: relative;

    &__toggle {
        background-color: $danger;
        color: $ui-color-white;
        height: 2.25em;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        span {
            white-space: nowrap;
        }

        .flyout-button--big & {
            height: 40px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__chevron {
        transition: 0.1s linear;
        margin: 0 0 0 0.3em;

        .flyout-button--active & {
            transform: rotate(180deg);
        }
    }
}
