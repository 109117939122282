@use "sass:math";
@import "./../../grid/_grid-factory";
@import "./../../utils";

$slider-spacing: 20px;
$slider-auto-spacing: 10px;
$minimum-slides: 1;
$maximum-slides: 6;

$bp-step: 0.2;

$bp-map: (
    "xs": $XS,
    "sm": $S,
    "md": $M,
    "lg": $L,
    "xl": $XL,
);

@mixin slideLimit($slides, $sliderName) {
    .swiper:not(.swiper-initialized) .#{$sliderName}-slide {
        width: calc(
            (100% / #{$slides}) - #{$slider-spacing} + (#{$slider-spacing} / (#{$slides}))
        );
        margin-right: $slider-spacing;
    }
}

@mixin includeSwiperLayoutFor($sliderName) {
    .slider-layout-auto {
        .swiper:not(.swiper-initialized) .#{$sliderName}-slide {
            margin-right: $slider-auto-spacing;
        }
    }

    @each $name, $bp in $bp-map {
        @for $i
            from $minimum-slides
            through (math.div($maximum-slides - $minimum-slides, $bp-step) + 1)
        {
            $value: $minimum-slides + ($bp-step * ($i - 1));
            $t_value: str-replace(#{$value}, ".", "_");

            @include breakpoint($bp) {
                .slider-layout-#{$name}-#{$t_value} {
                    @include slideLimit($value, $sliderName);
                }
            }
        }
    }
}
