.image-component {
    display: block;
    height: auto;

    &__button {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
        padding: 0;
    }
}
