@import "/lib/styles/styleguide/grid/grid";

.iframe {
    border: none;
    width: 100%;

    &--static {
        height: 130dvw;
        @include breakpoint(M) {
            height: 60dvw;
        }
    }

    &--dynamic {
        height: 0;
        position: relative;
    }
}
