@use "sass:map";

@import "/lib/styles/styleguide/grid/_grid-factory";
@import "/lib/styles/styleguide/color/_color-variables";
@import "/lib/styles/styleguide/spacing/_spacing-factory";
@import "/lib/styles/styleguide/vendor-overrides/swiper/_swiperLayout";

@include includeSwiperLayoutFor("ssr");

.productSlider {
    background-color: $ui-color-white;
    position: relative;

    &__slider {
        position: relative;

        & > div {
            position: unset;
        }

        &__prev {
            right: 50%;
            margin-right: 45px;
        }

        &__next {
            left: 50%;
            margin-left: 45px;
        }
    }
}

.productSlider-slide {
    display: flex;
    height: auto;

    & > div {
        width: 100%;
    }

    .productTile {
        margin-bottom: 1rem;

        .productTile__rows {
            .productTile__row {
                &:last-child {
                    &.productTile__row--PRODUCT_PRICE {
                        padding-bottom: 1rem;
                    }
                }
            }
        }

        &__row--TITLE {
            flex-grow: 1;
            align-items: start;
        }
    }
}

.productSlider-recents {
    background-color: $ui-color-grey;
    padding: 1em 0;
}

.productSlider-pdpContainer {
    margin: 0 -1rem;
}

@include breakpoint($S) {
    .productSlider-recents {
        margin-top: 1.5em;
    }
}

@include breakpoint($XS) {
    .productSlider-recents {
        margin-top: 1.5em;
    }
}

.productSlider-fullwidth {
    @media (max-width: #{map.get($grid-breakpoints, lg)}) {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background-color: $ui-color-grey;
    }
}

.productSlider-deleteButton {
    position: absolute;
    top: 0;
    padding: 0.1em 0.3em;
    left: 0;
    font-size: 1.255em;
    z-index: 1;
}

.swiper-wrapper {
    width: 100vw;
    max-width: 100%;

    .swiper-slide:not(.imageViewerFullWidth__thumb-slide) {
        .image-component {
            width: 100%;
            max-width: max-content;
            margin-right: auto;
        }
    }

    .swiper-slide.imageViewer-thumbnail .image-component {
        width: auto;
    }
}
