.compare-product {
  color: #000;

  label {
    cursor: pointer !important;
  }

  .form-check-input {
    position: relative;
    margin-left: 0;
    margin-right: 0.5rem;
  }
}
