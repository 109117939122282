@import "../../../styles/styleguide/color/color-variables";
@import "../../../styles/styleguide/grid/grid-factory";
@import "../../../styles/styleguide/spacing/spacing-factory";

.content-banner {
    position: relative;

    min-height: 500px;

    align-items: flex-end;
    display: flex;

    background-size: cover;

    &--center {
        align-items: center;

        text-align: center;
    }

    &__gradient {
        position: absolute;

        width: 100%;
        height: 100%;
        @include opacity(opacity-5);

        &--left {
            background: linear-gradient(
                to top,
                $ui-color-primary-black 0%,
                transparent 75%
            );
        }

        &--center {
            background-color: $ui-color-primary-black;
        }

        &--right {
            background: linear-gradient(
                to left,
                $ui-color-primary-black 0%,
                transparent 75%
            );
        }
    }

    &__text-wrapper {
        @include padding-top(spacing-8);
        @include padding-bottom(spacing-8);
        .link {
            @include margin-bottom(spacing-0);
        }
    }
}

@include breakpoint($S) {
    .content-banner {
        min-height: 408px;

        align-items: center;

        &--left,
        &--right {
            align-items: center;
        }

        &__gradient {
            &--left {
                background: linear-gradient(
                    to right,
                    $ui-color-primary-black 0%,
                    transparent 50%
                );
            }

            &--right {
                background: linear-gradient(
                    to left,
                    $ui-color-primary-black 0%,
                    transparent 50%
                );
            }
        }
    }
}

@include breakpoint($M) {
    .content-banner {
        min-height: 544px;

        align-items: center;
    }
}

@include breakpoint($L) {
    .content-banner {
        min-height: 680px;

        align-items: center;
    }
}
