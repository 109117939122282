@import "/lib/styles/styleguide/color/color-variables";

.store-search-input {
    display: flex;

    &__select {
        flex-shrink: 1 !important;
        min-width: 0;
        border: 1px solid $primary;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;

        & > div {
            border: none;
            box-shadow: none;
            transition: outline-offset 100ms ease-in-out,
                outline 100ms ease-in-out !important;
            outline: 0px solid $ui-color-searchbox-focus !important;
            outline-offset: 0;

            *:not(i) {
                font-weight: normal;
            }

            &:hover {
                border: none;
            }

            &:focus-within {
                border: none;
                outline: 3px solid $ui-color-searchbox-focus !important;
                outline-offset: -3px;
            }
        }
    }

    &__submit {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        flex: 0 0 auto;
    }
}
