@import "../../../../../styles/styleguide/color/color-variables";

.language-selector {
    &__dropdown {
        button {
            transition: none;
            background-color: transparent !important;
            color: $dark !important;
            border: none !important;
            box-shadow: none !important;

            &:hover {
                background-color: transparent !important;
                color: $dark !important;
                border: none !important;
                box-shadow: none !important;
            }

            &:active {
                background-color: transparent !important;
                color: $dark !important;
                border: none !important;
                box-shadow: none !important;
            }

            &:focus {
                background-color: transparent !important;
                color: $dark !important;
                border: none !important;
                box-shadow: none !important;
            }
        }

        .dropdown {
            &-menu {
                min-width: 3rem;
                margin: 0;
                padding: 0;
            }

            &-item {
                text-align: center;
                width: auto;
                padding: 0.5rem;
                border-radius: 0.25rem;
            }
        }

        &-item {
            a {
                color: $text-color;
                text-decoration: none;
            }

            &:active,
            :focus {
                color: $dark;
            }

            &:active,
            :hover {
                background-color: $ui-color-grey !important;
            }
        }
    }
}
