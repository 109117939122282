@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/layer/layer";
@import "/lib/components/Navigation/navigation-component-variables";

.sidebar {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 2.5em;

    &__container {
        width: calc(100% - 50px);
        height: 100%;
        background-color: $ui-color-white;
        border-right: 1px solid $ui-color-light-grey;
        position: fixed;
        overflow-x: hidden;
        float: left;
        left: 0;
        top: 0;
        @include z-index(overlay, 2);
    }

    &__submenu {
        display: flex;
        flex-direction: column;

        &__back {
            padding: 0.75rem 1.25rem;
        }

        &__title {
            font-weight: bold;
            border-bottom: 1px solid $ui-color-light-grey;
            padding: 0.5rem 1.25rem;
        }
    }

    &__assortiment {
        display: flex;
        align-items: center;
        background-color: $ui-color-grey;

        &:hover {
            cursor: pointer;
            background-color: $ui-gray-03;
        }

        a {
            flex-grow: 1;
        }

        &__chevron {
            transition: 0.1s linear;

            &__active {
                transform: rotate(180deg);
            }
        }

        &__item {
            display: flex;
            align-items: center;
            padding: 0.5rem 1.25rem 0.5rem 3rem;

            a {
                flex-grow: 1;
            }

            &--underlined {
                border-bottom: 1px solid $ui-color-light-grey;
            }
        }
    }

    &__item {
        padding: 0.5rem 1.25rem;

        &--lined {
            border-top: 1px solid $ui-color-light-grey;
        }

        &--lined,
        &--underlined {
            border-bottom: 1px solid $ui-color-light-grey;
        }
    }

    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            flex-grow: 1;
        }
    }

    &__languages {
        position: absolute;
        margin-top: 3.5em;

        div {
            display: flex;
            flex-direction: column;
            text-transform: uppercase;

            a {
                padding: 0.25em 1.25em;
            }
        }
    }

    &__linkgroup {
        &--seperated {
            div {
                a {
                    border-bottom: 1px solid $ui-color-light-grey;
                }
            }
        }

        .pl-component__partition {
            padding-right: 0 !important;

            &:first-child {
                div {
                    a {
                        padding-bottom: 0.325rem;
                    }
                }
            }

            div {
                a {
                    padding-top: 0.325rem;
                    padding-bottom: 0.325rem;
                }
            }

            &:last-child {
                div {
                    a {
                        padding-top: 0.325rem;
                    }
                }
            }
        }

        div {
            display: flex;
            flex-direction: column !important;
            flex-wrap: nowrap !important;

            a {
                color: $information;
                margin: 0 !important;
                padding: 0.75rem 1.25rem;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__lightbox {
        @include z-index(overlay, 1);
    }
}
