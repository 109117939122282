@import "../../../../../../styles/styleguide/color/color-variables";
@import "../../../../../../styles/styleguide/spacing/spacing";
@import "../../../../../../styles/styleguide/vendor-overrides/bootstrap/custom";

.storePicker-autoComplete__dropdown-menu {
    border: 1px solid #063cff;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: $input-focus-box-shadow;
    overflow-y: hidden !important;
    z-index: 600;
}

.storePicker-autoComplete__dropdown-item {
    background-clip: padding-box;
    border-bottom: 1px solid $ui-gray-04;
    cursor: pointer;
    display: block;
    white-space: pre-wrap;

    @include padding(spacing-3);

    &--active {
        background-color: $ui-gray-06;
    }

    &:last-child {
        border-bottom: none;
    }

    &:active,
    :focus {
        color: $dark;
    }

    &:hover,
    &.selected {
        background-color: $ui-gray-06;
    }

    &--first {
        background-color: $ui-gray-06;
    }
}
