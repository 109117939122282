@import "/lib/styles/styleguide/color/color-variables";
@import "card-variables";
@import "./../corner-radius/corner-radius";

@mixin cardFactory($corner-direction: "all") {
    box-shadow: $card-shadow;
    @include border-radius($card-border-radius, $corner-direction);
}

@mixin cardInactive() {
    transition: transform 100ms ease-in-out, box-shadow 100ms ease-in-out !important;
    transform: scale(1);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

@mixin cardActive() {
    @media (hover: hover) {
        background-color: $ui-color-white;
        transform: scale(1.02);
        box-shadow: $card-shadow--active;
    }
}
