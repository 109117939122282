@use "sass:map";

@import "/lib/styles/styleguide/grid/_grid-factory";
@import "/lib/styles/styleguide/color/_color-variables";
@import "/lib/styles/styleguide/spacing/_spacing-factory";
@import "/lib/styles/styleguide/utils";

.modal {
    background: none !important;
    z-index: 1041 !important;

    .store-picker-context .modal-content {
        overflow: visible;

        .modal-body {
            overflow: visible;
        }
    }

    &-title {
        margin-right: 2.3rem;

        @include smallHeightDevice {
            .store-picker-context & {
                margin: 0;
            }
        }

        h2 {
            max-height: 4.75em;
            overflow: hidden;

            @include smallHeightDevice {
                .store-picker-context & {
                    margin-bottom: 0.5rem;
                    margin-top: 0;
                }
            }
        }
    }

    &-dialog {
        z-index: 1041;
        cursor: auto;
    }

    &-close {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 2em;
        border: none;
        background: transparent;
        z-index: 1;

        @include smallHeightDevice {
            .store-picker-context & {
                padding: 1rem;
                margin: -0.5rem -1rem -1rem auto !important;
            }
        }
    }

    &-xxl {
        max-width: 1400px;
        margin-left: 30px;
        margin-right: 30px;
    }

    &-fullscreen {
        &-mobile {
            @media (max-width: #{map.get($grid-breakpoints, md)-1}) {
                min-height: calc(100% - 1rem);
                align-items: stretch;
            }
        }
    }
}
