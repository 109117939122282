.categoryLink {
    &__name {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
    }

    &__icon {
        font-size: 85%;
    }
}
