@import "/lib/styles/styleguide/shadow/shadow";
@import "/lib/styles/styleguide/card/card";
@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/grid/grid-factory";

.productsTemplate {
    &__items {
        &__item {
            background-color: $ui-color-white;
            overflow: visible;
            margin-bottom: 1rem;

            &.autocompleteItem {
                @include cardInactive();

                &--active, &:hover {
                    @include cardActive();
                }
            }
        }

        &--slider {
            .productTile {
                padding-bottom: 1rem;
            }
        }

        &--grid {
            display: none;
            width: 100%;

            @include breakpoint(L) {
                display: grid;
                gap: 1rem;
                overflow: visible;
                grid-template-columns: repeat(3, 1fr);

                > li {
                    min-width: 0;
                }
            }
        }
    }
}
