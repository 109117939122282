@use "sass:map";

@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/grid/grid-factory";

.amount-field {
    background-color: $ui-color-transparent;

    &--disabled {
        background: $ui-color-light-grey !important;
        pointer-events: none;
    }

    &__inputgroup-addon {
        span {
            background-color: $ui-color-white;
            color: $text-color !important;

            &:hover {
                cursor: pointer;
                background-color: $ui-color-grey !important;
            }
        }
    }

    &__inputgroup {
        width: 9.5rem;

        @media (min-width: #{map.get($grid-breakpoints, md)}) {
            width: 8.25rem;
        }

        @include breakpoint($M) {
            min-width: 8.25em;
            max-width: 8.25em;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type="number"] {
            appearance: textfield;
            text-align: center;
        }
    }
}
