@import "../../../styles/styleguide/spacing/_spacing-factory";
@import "../../../styles/styleguide/color/_color-variables";

.key-features-component {
    a {
        color: $secondary-link-color;
    }

    a:hover {
        color: $secondary-link-color-hover;
    }

    &__list {
        list-style-type: none;
        padding: 0;
    }

    &__item {
        border-top: 1px solid $ui-gray-04;
        display: flex;
        justify-content: space-between;
        @include padding-vertical(spacing-3);

        &:last-child {
            border-bottom: 1px solid $ui-gray-04;
        }
    }

    &__value {
        text-align: right;
    }
}
