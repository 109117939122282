@import "/lib/styles/styleguide/color/_color-variables";
@import "/lib/styles/styleguide/opacity/_opacity-factory";

$navigation-height--search: 58px !default;
$navigation-height--mobile: 52px !default;
$navigation-logo-height--mobile: 35px !default;

$navigation-height--desktop: 96px !default;
$navigation-logo-height--desktop: 50px !default;

$navigation-top-nav-icon-dimension: 24px !default;

$navigation-hamburger-menu-border-style: 1px solid mix-color($ui-color-white, $ui-color-primary-black, opacity-4) !default;
