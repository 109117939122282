@import "../../../styles/styleguide/color/color-variables";
@import "../../../styles/styleguide/font/font-variables";

.card {
    &__info {
        margin-bottom: 8px;
        border: 1px solid $ui-color-divider;
        border-radius: 0.25em;
        padding: 16px;

        .card__heading {
            font-weight: bold;
            margin-bottom: 8px;

            .heading__visual {
                display: flex;
                flex-direction: row-reverse;

                &__container {
                    height: 30px;

                    img {
                        height: 100%;
                    }
                }
            }
        }

        &--higlight {
            background-color: $ui-color-divider;
            padding: 0;
            border-color: transparent;

            .iconLabel {
                font-size: 1em;
            }

            a {
                padding: 16px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .content {
            padding: 0;
            margin: 0;

            p {
                padding-bottom: 0;
                margin-bottom: 0;
            }

            a {
                font-size: 14px;
                color: $information;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
