.hierarchicalFacet {
    li {
        button {
            background-color: transparent;
            padding: 0.15rem 0;
            border: none;
            width: 100%;
        }
    }
}
