//###################################
//####                           ####
//####    Overrides variables    ####
//####                           ####
//###################################

.tick {
    font-size: 2.5em;

    &-flip-panel-back {
        opacity: 0.9;
    }
}
