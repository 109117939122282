@import "/lib/styles/styleguide/color/color-variables";

.store-search-filters {
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 0.5;
    max-height: 100%;
    transition: max-height 150ms ease-in-out;
    overflow: auto;

    &__filter {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        label {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }
    }
}
