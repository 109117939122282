@import "../../../styles/styleguide/grid/grid-factory";
@import "../../../styles/styleguide/grid/grid-variables";
@import "../../../styles/styleguide/spacing/spacing-factory";

.content-cards-component {
    .col {
        flex-basis: auto;
        flex-grow: initial;

        padding-bottom: $grid-gutter-width;

        & > * {
            height: 100%;
        }
    }
}

@include breakpoint($S) {
    .cards {
        @include margin-bottom(spacing-7);
    }
}

@include breakpoint($M) {
    .cards {
        @include margin-bottom(spacing-8);
    }
}
