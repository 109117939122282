p, td {
    span.line-through {
        position: relative;
        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 45%;
            right: 0;
            border-top: 2px solid;
            border-color: inherit;
            transform: skewY(5deg);
        }
    }
    span.bigger-text {
        font-size: 1.2em;
    }
}
