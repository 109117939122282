@import "../../styles/styleguide/color/_color-variables";

.vacancyList {
    list-style: none !important;

    h1, h2, h3, h4, h5, h6 {
        color: $primary;
    }

    &__listButton {
        padding: 0;
    
        &:focus {
            text-decoration-line: none;
        }
    
        &:hover {
            text-decoration-line: underline;
        }
    }

    ul {
        list-style: none !important;
    }

    li {
        margin-bottom: 0 !important;

        &::before {
            content: "\25A0";
            color: $primary;
            display: inline-block;
            margin-left: -1.5em;
            padding-right: 0.8em;
            transform: translateY(-0.2em);
        }

        a:visited {
            color: $link-colorvisited;
        }
    }
}
