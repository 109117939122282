@import "/lib/styles/styleguide/color/color-variables";

.productCollectionBlock__title-bar {
    background-color: #f7f7f7;
    padding: 0.5em 1em;

    i {
        font-size: 23px;
        margin-right: 0.5em;
    }

    h5 {
        font-weight: bold;
    }
}
