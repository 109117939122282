@import "../grid/_grid-factory";

@mixin publitas-iframe() {
    // Used in AEM style system, applied on AEM div
    .publitas-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 130%;

        @include breakpoint($M) {
            padding-top: 66%;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
}
