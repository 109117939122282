.language-selector {
    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        &-item {
            display: block;

            a,
            span {
                display: block;
                padding: 0.25rem 1.25rem;
            }

            &:first-child a,
            &:first-child span {
                padding-top: 0.75rem;
            }

            &:last-child a,
            &:last-child span {
                padding-bottom: 0.75rem;
            }
        }
    }
}
