@use "sass:map";

@import "/lib/styles/styleguide/grid/_grid-factory";

.calculator-group {
    > div:not(:last-child) {
        margin-bottom: 0;
    }

    > div:not(:first-child) {
        @media (max-width: #{map.get($grid-breakpoints, sm)}) {
            margin-left: 15px;
        }
    }
}

.calculator {
    .form-check-input {
        position: relative;
        margin-left: 0;
    }
}

.calculator-image {
    max-width: 16em;
}

.calculator-image--large {
    width: 100%;
    max-width: 32em;
}
