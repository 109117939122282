.productImage-wrapper {
    position: relative;

    &__image {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    &__label {
        display: inline-block;
        height: 1.5em;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }
}
