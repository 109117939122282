@use "sass:map";

@import "/lib/styles/styleguide/anchor/_anchor-variables.scss";
@import "/lib/styles/styleguide/grid/grid-factory";

.anchor {
    pointer-events: none;

    &-span {
        float: left;
        height: 0px;
        margin-top: -4px;
        visibility: hidden;

        @media (min-width: #{map.get($grid-breakpoints, lg)}) {
            margin-top: -$anchor-offset;
        }
    }

    @media (max-width: #{map.get($grid-breakpoints, md) - 1}) {
        &.anchor-sm-hide {
            &::before {
                display: none;
            }
        }
    }

    &::before {
        display: block;
        height: $anchor-offset;
        margin-top: -$anchor-offset;
        visibility: hidden;
        content: "";

        @media (min-width: #{map.get($grid-breakpoints, lg)}) {
            height: $anchor-offset;
            margin-top: -$anchor-offset;
        }
    }

    &--pdp-info-nav {
        &::before {
            height: $pdp-info-block-navigation-height + $anchor-offset;
            margin-top: -($pdp-info-block-navigation-height + $anchor-offset);
        }
    }

    &--pdp-parents-slider {
        &::before {
            height: 250px;
            margin-top: -250px;
        }
    }
}
