@import "list-variables";
@import "../spacing/spacing-factory";
@import "../color/color-variables";

@mixin generate-list-base() {
    padding: 0;
    list-style-type: none;
    @include margin-bottom(spacing-7);

    li {
        @include margin-bottom(spacing-3);
    }
}

@mixin generate-list-bullet() {
    padding-left: 1em;
    list-style-type: disc;
}

@mixin generate-list-number() {
    counter-reset: item-counter;
    @include padding-left(spacing-7);

    li {
        // text-indent to make sure that multiple lines align correctly
        text-indent: -(_get-spacing(spacing-4));
        counter-increment: item-counter;

        &::before {
            content: counter(item-counter) ".";
            display: inline-block;
            // due to text-indent this deviates form the specs
            @include margin-right(spacing-3);
        }
    }

    li:first-child:nth-last-child(10),
    li:first-child:nth-last-child(10) ~ li {
        &::before {
            width: 8px;
        }
    }
}

@mixin list {
    .list {
        @include generate-list-base();

        &--bullet {
            @include generate-list-bullet();
        }

        &--number {
            @include generate-list-number();
        }

        &--sm {
            @include margin-bottom(spacing-3);

            .list__item {
                font-size: 12px;
                @include margin-bottom(spacing-0);
            }
        }
    }
}
