@import "../../../../styles/styleguide/color/color-variables";

.faq-categories {
    &__button {
        padding: 0;
        text-align: left;

        &:focus {
            text-decoration-line: none;
        }

        &:hover {
            text-decoration-line: underline;
        }

        &--active {
            color: gray;
        }
    }
}
