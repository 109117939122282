@import "../../../../../styles/styleguide/color/_color-variables";

.wishlist-item {
  cursor: pointer;

  &__current {
    background: $primary;
    color: $ui-color-white;

    &:hover {
      color: $ui-color-white;
    }
  }
  &__count {
    float: right;
    font-size: 0.85em;
    font-weight: 300;
    margin: 0;

  }
}