.calculator-result-title {
    border-top: solid thin lightgray;
    padding-bottom: 2em;
    margin-top: 2em;

    &-field {
        padding-top: 2em;

        &__button {
            float: right;
            width: max-content;
        }

        &__title {
            display: inline;
        }
    }
}
