@use "sass:map";

@import "label-variables";
@import "label-factory";

@include _generate-label-class(
    map.get($label-default, font-size),
    map.get($label-default, font-weight),
    map.get($label-default, line-height),
    map.get($label-default, color),
    map.get($label-default, background-color),
    map.get($label-default, display),
    map.get($label-default, padding-left-right),
    map.get($label-default, padding-top-bottom)
);

@each $label-class, $label-style-map in $label-theme-modifiers {
    @include _generate-label-theme-modifier-class(
        $label-class,
        map.get($label-style-map, font-color),
        map.get($label-style-map, background)
    );
}
