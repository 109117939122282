@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/card/card";
@import "/lib/styles/styleguide/grid/grid-factory";

@mixin promotionLabelFullWidth {
    margin-right: 0 !important;

    > span {
        width: 100%;
        display: flex;

        > span:first-of-type {
            width: 100%;
        }

        > span:not(:first-of-type) {
            flex-grow: 1;
        }
    }
}

.aem-Grid > .product-tile-component {
    margin-bottom: 20px;
}

.productTile {
    background-color: $ui-color-white;
    cursor: pointer;

    &--noLink {
        cursor: default !important;
    }

    &--hoverEnabled {
        @include cardInactive();

        &:hover {
            @include cardActive();
        }
    }

    &--border,
    &--padding {
        padding: $card-border-padding--small;
        @include breakpoint($M) {
            padding: $card-border-padding;
        }

        .productTile__promotionRibbon {
            transform: translateX(-$card-border-padding--small);
            @include breakpoint($M) {
                transform: translateX(-$card-border-padding);
            }
        }
    }

    &__features {
        color: $ui-color-primary-black;
    }

    &__wishListButtons {
        i {
            font-size: 1.5em;
            color: $secondary-link-color;
        }
    }

    &__deliveryType {
        display: flex;
        flex-direction: column;

        @include breakpoint($S) {
            flex-direction: row;
        }

        &-item {
            display: flex;

            &_icon::before {
                content: "";
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: $success;
                margin-right: 6px;
            }
        }
    }

    &__price,
    &__label {
        height: 2rem;
        text-align: right;
        align-items: center;

        & > * {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    &__cols {
        display: grid;
        gap: 1rem;
    }

    &__rows {
        min-width: 0;
    }

    &__row {
        align-items: center;

        &:not(:first-child):not(:has(.productTile__title)) {
            margin-top: 0.5rem;
        }

        &:not(:last-child):not(:has(.productTile__title)) {
            margin-bottom: 0.5rem;
        }

        &:has(.productTile__features) {
            height: 100%;
            align-items: flex-start;
        }
    }

    .productTile__row--CATEGORY_LINK {
        margin: 0 !important;
    }

    &--horizontal {
        .productTile {
            &__rows:first-of-type {
                .productTile__row--PRODUCT_IMAGE
                    + .productTile__row--PRODUCT_PRICE {
                    .productTile__price {
                        font-size: 1.4em;
                        margin: auto;
                    }
                }
            }
        }
    }

    &--vertical {
        .productTile {
            &__row {
                & > *:only-child {
                    width: 100%;

                    &.productTile__promotionLabel {
                        @include promotionLabelFullWidth;
                    }
                }
            }
        }
    }

    &--s {
        .productTile__row--PROMOTION_LABEL.productTile__row--PRODUCT_PRICE {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .productTile__promotionLabel {
                width: 100%;
                @include promotionLabelFullWidth;
            }

            .productTile__price {
                & > * {
                    margin-bottom: 0;
                }
            }
        }
    }
}
