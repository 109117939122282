@use "sass:map";

@import "/lib/styles/styleguide/grid/_grid-factory";
@import "/lib/styles/styleguide/color/_color-variables";
@import "/lib/styles/styleguide/variables";
@import "./productComparison.variables.scss";

$mobile_cell_with: 12.5rem;

$comparison__breakpoint--height: 500px;

@mixin comparison-large() {
    @include breakpoint(M) {
        @media (min-height: $comparison__breakpoint--height) {
            @content;
        }
    }
}

@mixin comparison-small() {
    @media (max-width: #{map.get($grid-breakpoints, md)}), (max-height: ($comparison__breakpoint--height - 1)) {
        @content;
    }
}

.product-comparison {
    position: relative;

    &__wrapper {
        position: relative;
    }

    &__products-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
    }

    &__navigation {
        display: none;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        z-index: 10;
        font-size: 1.2rem;
        cursor: pointer;
        margin: auto 0;
        transition: all 200ms ease-in-out;
        opacity: 0;
        scale: 0;
        transform-origin: 50% 0;

        @include comparison-large() {
            display: block;
        }

        &--visible {
            opacity: 1;
            scale: 1;
        }

        &--prev {
            left: 18rem;
        }

        &--next {
            right: 0.5rem;
        }
    }

    &__products {
        background-color: white;
        position: sticky;
        top: -1px;
        display: flex;
        z-index: 5;
        -ms-overflow-style: none;
        scrollbar-width: none; /* Firefox */
        pointer-events: all;

        &-container {
            width: 100%;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__product {
        transition: height 200ms ease-in-out;
        align-self: stretch;
        margin: 1rem 0 0 0;
        padding: 0 1rem 1rem 1rem;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: start;

        width: $product-tile__width--xs;
        height: $products__height--xs;
        @include comparison-large() {
            width: $product-tile__width--md;
            height: $products__height--md;
        }

        .productTile {
            margin-bottom: 1rem;

            *:not(.productImage-wrapper) {
                transition: all 200ms ease-in-out;
            }

            width: 100%;

            &__title {
                height: 2.5rem !important;
            }

            &__row--PROMOTION_LABEL,
            &__row--PRODUCT_PRICE {
                height: 2rem;
            }
        }

        &__close {
            aspect-ratio: 1 / 1;
            position: absolute;
            top: 0.2rem;
            left: 0.2rem;
            z-index: 1;
        }

        &:not(:last-child) {
            border-right: 1px solid $ui-gray-04;
        }
    }

    &__filler {
        display: none;

        @include comparison-large() {
            display: flex;
            width: $product-tile__width--md;
            align-items: flex-end;
            margin-top: auto;
        }
    }

    &__features {
        overflow-x: auto;
        overflow-y: auto;
        display: flex;
        transition-duration: 0ms;
        overscroll-behavior-x: none;

        padding-top: calc($products__height--xs--pinned + 5rem);
        @include comparison-large() {
            padding-top: calc($products__height--md--pinned + 5rem);
        }

        &-container {
            flex: 0 0 auto;
            min-width: 100%;
        }
    }

    &__row {
        display: flex;
        white-space: nowrap;
        position: relative;
    }

    &__cell {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &__cell--feature-name {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 1;
        flex: 0;
        width: 0;
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
        font-size: 0.85rem;
        font-weight: bold;

        @include comparison-large() {
            flex: 17.5rem;
            width: 17.5rem;
            max-width: 17.5rem;
            background-color: $ui-gray-06;
            padding-left: 1rem;
            padding-right: 1rem;
            font-weight: normal;
            font-size: medium;
        }
    }

    &__cell--value {
        flex: 17.5rem;
        width: 17.5rem;
        max-width: 17.5rem;

        @include comparison-small() {
            flex: $mobile_cell_with;
            width: $mobile_cell_with;
            max-width: $mobile_cell_with;
        }
    }

    &__feature-name {
        display: flex;
        align-items: center;

        &__name {
            display: inline-block;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include comparison-small() {
                flex: unset;
            }
        }

        @include comparison-small() {
            padding-top: 0.5rem !important;
            padding-left: 1rem;
            padding-right: 1rem;
            width: $mobile_cell_with;
            max-width: $mobile_cell_with;
        }
    }

    &__value {
        span {
            width: 100%;
            display: inline-block;
            white-space: normal;
        }

        @include comparison-small() {
            margin-top: 1.15rem;
        }
    }

    &__tooltip__image {
        width: auto;
        height: 200px;
    }

    .tooltip-component {
        margin-left: 0.5rem;
        display: inline-block;
    }

    .swiper-wrapper {
        transition-timing-function: ease-in-out;
    }

    &__actions {
        &--sm {
            display: block;
            @include comparison-large() {
                display: none;
            }
        }

        &--md {
            display: none;
            width: $product-tile__width--md;
            position: absolute;
            background-color: #fff;
            z-index: 2;
            height: 100%;

            @include comparison-large() {
                display: block;
            }

            .product-comparison__actions {
                bottom: 1rem;
            }
        }
    }

    &--is-pinned {
        .product-comparison__product__close {
            display: none;
        }

        .product-comparison__products {
            position: sticky;
        }

        .product-comparison__product {
            justify-content: start;

            height: $products__height--xs--pinned;
            @include comparison-large() {
                height: $products__height--md--pinned;
            }
        }

        .productTile {
            &__row--PROMOTION_LABEL,
            &__row--PRODUCT_PRICE {
                height: 0px;
                opacity: 0;
                margin: 0 !important;
            }

            @include comparison-large() {
                &__row--PROMOTION_LABEL,
                &__row--PRODUCT_PRICE {
                    display: flex !important;
                }
            }
        }
    }
}

@media print {
    .las,
    .btn,
    .product-comparison__1,
    .product-comparison__product__close,
    .product-comparison__navigation {
        display: none !important;
    }

    .product-comparison__actions {
        display: none;
    }

    .product-comparison__features {
        padding-top: $products__height--xs--pinned;
    }
}
