@import "../../styles/styleguide/color/color-variables";
@import "../../styles/styleguide/icon/icon-variables";
@import "../../styles/styleguide/spacing/spacing-factory";
@import "../../styles/styleguide/grid/grid-factory";

// key = class name suffix
// values = maximum amount of items per breakpoint
$uspHorizontalSettings: (
    3: (
        $L: 3,
        $M: 2,
    ),
);

@mixin usp-bar-component-generator($maxIconsConfig) {
    @each $maxIconsClassSuffix, $maxIconsByBreakpoint in $maxIconsConfig {
        @each $breakpoint, $maximumItems in $maxIconsByBreakpoint {
            @include breakpoint($breakpoint) {
                .usp-bar-component--horizontal-#{$maxIconsClassSuffix} {
                    .usp-bar__item {
                        &:nth-child(#{"-n + "}#{$maximumItems}) {
                            display: flex;
                            @include padding-right(spacing-0);
                        }
                    }
                }
            }
        }
        .usp-bar-component--horizontal-#{$maxIconsClassSuffix} {
            .usp-bar {
                display: flex;
                @include margin-bottom(spacing-4);
                @include margin-top(spacing-4);

                &__item {
                    flex: 1;
                    justify-content: center;
                    display: none;
                    @include padding-left(spacing-5);
                    @include padding-right(spacing-5);
                    @include margin-bottom(spacing-0);

                    &-description {
                        flex: unset;
                    }

                    &:first-child {
                        display: flex;
                        @include padding-left(spacing-0);
                    }
                }
            }
        }
    }
}

@include usp-bar-component-generator($uspHorizontalSettings);
.usp-bar {
    padding: 0;
    @include margin-bottom(spacing-4);
    @include margin-top(spacing-4);

    &__item {
        align-items: flex-start;
        display: flex;
        padding: 0.5rem 0;

        &-icon {
            @include margin-right(spacing-4);
            i {
                width: $icon-medium-width;
                height: $icon-medium-height;
                font-size: $icon-medium-width;
            }
        }

        &-description {
            flex: 1;
            margin-bottom: 0;
        }
    }
}
