@mixin clamp($lines: 1) {
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
}

@each $amountOfLines in (1, 2, 3, 4, 5) {
    .clamp-#{$amountOfLines} {
        @include clamp($amountOfLines);
    }
}
