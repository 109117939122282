@use "sass:color";
@import "../../../../styles/styleguide/color/_color-variables";

.product-label {
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    text-transform: uppercase;
    line-height: 20px;
    display: inline-block;

    .text {
        background-color: $danger;
        color: $ui-color-white;
        border-radius: 0.25em;
        display: inline-block;
    }

    &__secondary {
        .text {
            border-radius: 0.25em 0 0 0.25em;
            float: left;
        }
    }

    .secondary {
        background-color: $ui-color-yellow;
        color: $danger;
        display: inline-block;
        border-radius: 0 0.25em 0.25em 0;
    }

    &--ribbon {

        .text {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 5px solid $danger;
            margin-left: -5px;

            &::before {
                background-color: color.adjust($danger, $lightness: -10%); 
                width:5px; 
                height:6px;
                content:'';
                display:block; 
                transform:skewY(45deg);
                position:absolute;
                z-index:-1;
                bottom: -2.5px;
                left: -5px;
            }
        }
    }
}

.product-label--small {
    font-size: 12px !important;
}

.product-label--extra-small .text {
    font-size: 10px !important;
}
