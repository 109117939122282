@use "sass:math";

@import "../../../styles/styleguide/spacing/spacing-factory";
@import "../../../styles/styleguide/grid/grid-variables";

.section-cmp {
    .aem-Grid {
        width: auto;

        margin: 0 -#{math.div($grid-gutter-width, 2)};
    }

    .aem-GridColumn {
        padding: 0 math.div($grid-gutter-width, 2);
    }

    .container,
    .container--fluid {
        &.spacing-8 {
            @include padding-top(spacing-8);
            @include padding-bottom(spacing-8);
        }
    }
}

main > .aem-Grid {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > div:last-child {
        margin-top: auto;
    }
}
