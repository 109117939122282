@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

@import "../../color/color-variables";
@import "../../grid/grid-variables";

//###################################
//####                           ####
//####    Overrides variables    ####
//####                           ####
//###################################

$enable-responsive-font-sizes: true;

$font-family: "Roboto Condensed", Arial, sans-serif;
$font-size: 16; // pixel-value
$font-weight: 400;
$line-height: 1.6; // em-value

$font-family-base: $font-family;
$font-size-base: 1rem;
$font-weight-base: $font-weight;
$line-height-base: $line-height;
$input-placeholder-color: $ui-gray-02;
$input-focus-box-shadow: inset 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$small-font-size: 85%;

$h1-font-size: $font-size-base * 1.875;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.12;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base * 1;

$breadcrumb-divider: quote("\F105");

//** Background color for `<body>`.
$body-bg: $ui-gray-06;

//** Colors
$primary: $primary;
$secondary: $success;
$success: $secondary;
$info: $information;
$warning: $warning;
$danger: $danger;
$light: #fff;
$yellow: $ui-color-yellow;
$light-gray: $ui-color-grey;
$gray: $ui-gray-04;

$theme-colors: (
    "yellow": $yellow,
    "gray": $gray,
    "light-gray": $light-gray,
);

$grid-breakpoints: (
    xxs: 0,
    xs: 360px,
    sm: 480px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

$container-max-widths: (
    xl: 1440px,
);

$small-font-size: 85%;

$form-feedback-icon-valid: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='#{$success}' d='M28.281 6.281L11 23.563 3.719 16.28 2.28 17.72l8 8 .719.687.719-.687 18-18L28.28 6.28z'/%3E%3C/svg%3E");
$form-feedback-icon-invalid: none;

$print-body-min-width: 100%;

a:focus,
button:focus,
input:focus,
div:focus,
textarea:focus {
    outline: none;
}

.input-group-text {
    padding: 0.375rem 0.5rem !important;
}

.form-check-input {
    margin-top: 6px !important;
}

.card {
    margin-bottom: 1em;

    &::after {
        position: static !important;
    }
}

.modal-header {
    .modal-close {
        color: $ui-color-primary-black;
    }

    .modal-title {
        .title {
            margin: 0 !important;
            max-width: 90%;
        }
    }
}

.nav-link {
    padding: 0.5rem 0 0.5rem 1rem !important;
}

.dropdown-toggle::after {
    text-indent: 0;
}

.rich-text-content {
    :not(li) > a {
        text-decoration: underline;
    }

    a:visited {
        color: $link-colorvisited;
    }
}
