@import "/lib/styles/styleguide/shadow/shadow-variables";
@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/grid/grid";
@import "/lib/components/Navigation/navigation-component-variables";
@import "/lib/styles/styleguide/layer/layer";
@import "/lib/styles/styleguide/corner-radius/corner-radius-factory";

.resultsPanel {
    margin-top: 1rem;
    background-color: $ui-color-white;
    display: flex;
    flex-direction: column;
    font-size: 1rem !important;

    border-top: 1px solid $ui-color-divider;
    padding-top: 1rem;
    overflow: auto;
    height: 100%;
    padding-bottom: 2rem;

    @include breakpoint(L) {
        display: grid;
        gap: 1rem;
        grid-template-columns: 9fr 16fr;
        max-height: 75vh;

        border-top: none;
        padding-top: 0;
        overflow: visible;
        height: auto;
        padding-bottom: 0;

        > * {
            min-width: 0;
        }
    }
}
