@import "../../styles/styleguide/grid/grid-factory";
@import "../../styles/styleguide/spacing/_spacing-factory";

@include breakpoint($XS) {
    .cart__checkout-wrapper {
        flex-direction: column;

        div:first-child {
            @include margin-bottom(spacing-5);
        }
    }
}

@include breakpoint($M) {
    .cart__checkout-wrapper {
        display: none;
    }
}

@include breakpoint($L) {
    .cart__checkout-wrapper {
        @include margin-bottom(spacing-0);
    }
}
