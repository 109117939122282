@use "sass:map";

@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/grid/grid-factory";

.header-button {
    position: relative;

    &__element {
        position: relative;
        color: $primary;
        white-space: nowrap;
        margin: 0;
        text-decoration: none !important;

        @media (max-width: #{map.get($grid-breakpoints, lg)-1}) {
            padding: 0;
        }

        &:hover {
            cursor: pointer;
        }

        &:hover,
        &:focus {
            @media (min-width: #{map.get($grid-breakpoints, lg)}) {
                color: $primary-focus;
            }
        }

        &--active {
            color: $primary;
            z-index: 3;

            @media (min-width: #{map.get($grid-breakpoints, lg)}) {
                color: $primary;
            }

            &.header-button__element--invert,
            &.header-button__element--invert:hover,
            &.header-button__element--invert:focus {
                @media (min-width: #{map.get($grid-breakpoints, lg)}) {
                    color: $primary;
                }
            }
        }

        &--invert {
            color: $ui-color-white;

            &:hover,
            &:focus {
                color: $ui-color-white;
            }
        }
    }

    &__chevron {
        font-size: 1em;
        line-height: 100%;
        transition: 0.1s linear;
        margin: 0 0 0 0.3em;
        height: 100%;
        align-items: center;

        &--active {
            transform: rotate(180deg);
        }
    }

    &__icon {
        font-size: 1.8em;
        position: relative;

        &--alerts {
            .header-button__icon & {
                border-radius: 100%;
                padding: 0 2px;
                min-width: 21px;
                height: 21px;
                width: auto;
                position: absolute;
                top: -4px;
                right: -3px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                text-align: center;
                color: $ui-color-white;
                background-color: $secondary;
            }
        }
    }

    &__content {
        z-index: 2;
        display: none;
        position: absolute;
        top: -8px;
        right: -8px;
        width: 240px;
        min-width: 100%;
        padding: 35px 0.5rem 0.5rem 0.5rem;

        &--large {
            width: 400px;
        }

        &--active {
            @media (min-width: #{map.get($grid-breakpoints, lg)}) {
                display: block;
                animation: scale-drop-down 0.5s cubic-bezier(0.1, 1.45, 0.35, 1);
                transform-origin: top right;
            }
        }
    }
}

@keyframes scale-drop-down {
    0% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}
