@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/layer/layer";

.profileSidebar {
    width: calc(100% - 50px);
    background-color: $ui-color-white;
    border-right: 1px solid $ui-color-light-grey;
    position: fixed;
    overflow-y: auto;
    left: 0;
    top: 0;
    height: 100%;
    padding-bottom: 2.5em;

    &__close {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        font-size: 1.6em;
        cursor: pointer;

        &:hover {
            background-color: transparent;
        }
    }
}
