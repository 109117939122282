@import "../spacing/spacing-factory";
@import "../color/color-variables";
@import "../positioning/positioning";
@import "../grid/grid-factory";

@mixin box-banner-component() {
    .box-banner {
        &--container {
            position: relative;
            width: 100%;
            overflow: hidden;
        }
        &--image {
            width: 100%;
            .image-component__image {
                width: 100%;
            }
        }
        &--counter {
            position: absolute;
        }
    }
}
