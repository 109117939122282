@import "../../../../styles/styleguide/color/_color-variables";

.feature-list-component {
    &__item {
        &--gray {
            background-color: $ui-gray-06;
        }
    }
}

.feature-labels {
    &__image {
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
        padding: 0.25rem;
        max-width: 56px;
        height: auto;
        background-color: transparent;
    }
}
