.content-list {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 1em;

    > div {
        flex: 1;
        border-left: solid thin lightgray;
    }

    > div:first-of-type {
        border-left: none;
    }
}

.author-content-list {
    display: flex;
    flex-wrap: wrap;

    > div {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 25%;
        min-height: 6em;
        min-width: 15em;
        &.newpar {
            min-width: 100%;
            min-height: auto;
        }
    }
}
