@import "/lib/styles/styleguide/color/color-variables";

.productBundle {
    border: 1px solid $ui-color-divider;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;

    &__bundle {
        &:not(:last-of-type) {
            margin-bottom: 2rem;
        }

        &:last-of-type {
            .productBundle__buy {
                border-bottom-left-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }
        }
    }

    &__buy {
        background-color: $ui-color-grey;
    }

    &__separator {
        font-size: 30px;
    }
}
