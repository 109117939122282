@import "../../../styles/styleguide/spacing/spacing-factory";

.image-component,
.page-image-component {
    @include margin-bottom(spacing-4);

    img {
        width: 100%;
        height: auto;
    }
}

.imageViewerFullWidth-thumbnail .image-component {
    margin: 0;
    width: 100%;
}