@mixin truncate() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate__wrapper {
    min-width: 0;
}

.truncate {
    @include truncate();
}
