@use "sass:map";

@import "/lib/styles/styleguide/grid/_grid-factory";

.store-overview {
    column-count: 1;

    @media (min-width: #{map.get($grid-breakpoints, md)}) {
        column-count: 2;
    }
    @media (min-width: #{map.get($grid-breakpoints, lg)}) {
        column-count: 3;
    }
}
