@import "../../../styles/styleguide/spacing/spacing-factory";
@import "../../../styles/styleguide/grid/grid-factory";
@import "../../../styles/styleguide/color/_color-variables";

$content-card-m-min-height: 325px;
$content-card-m-horizontal-min-height: 305px;
$card-title-color-blue: $ui-color-blue;
$card-text-color: $text-color !default;
$card-bg-yellow: $ui-color-yellow !default;
$card-bg-blue: $ui-color-blue !default;

.card {
    &--content-card {
        background-color: $ui-color-white;
        border: none;

        &:hover,
        &:visited,
        &:link,
        &:active {
            text-decoration: none;
        }

        &-vertical,
        &-horizontal {
            .card {
                &__inner-wrapper {
                    text-align: left;
                    @include padding(spacing-0);
                }

                &__body {
                    @include padding-top(spacing-2);
                    @include padding-bottom(spacing-2);
                    @include padding-right(spacing-0);
                    @include padding-left(spacing-0);
                }

                &__title {
                    color: $card-title-color-blue;
                }

                &__image {
                    &:hover {
                        background-color: #fff;
                        opacity: 0.8;
                        border-radius: 2px;
                        overflow: hidden;
                        -webkit-transition: opacity 0.22s ease-in-out;
                        transition: opacity 0.22s ease-in-out;
                    }
                }
            }
        }
    }

    &__inner-wrapper {
        overflow: hidden;

        > * {
            width: 100%;
        }
    }
}

@include breakpoint($M) {
    .card {
        &--content-card {
            &-vertical,
            &-horizontal {
                .card {
                    &__inner-wrapper {
                        min-height: $content-card-m-min-height;

                        align-items: stretch;
                    }

                    &__image {
                        @include margin-bottom(spacing-0);

                        &-wrapper {
                            position: relative;

                            align-self: center;
                        }
                    }

                    &__body {
                        align-self: center;
                    }
                }
            }
        }
    }
}

@include breakpoint($L) {
    .card {
        &--content-card {
            &-vertical {
                .card {
                    &__body {
                        flex-direction: column;
                        display: flex;
                    }

                    &__inner-wrapper {
                        height: 100%;

                        flex-direction: column;

                        display: flex;
                    }

                    &__image-wrapper {
                        &::before {
                            content: "";
                            display: block;
                        }

                        picture {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &__image {
                        height: 100%;
                    }
                }
            }

            &-horizontal {
                .card__inner-wrapper {
                    overflow: hidden;

                    height: 100%;
                    min-height: $content-card-m-horizontal-min-height;
                }

                .card__image-wrapper {
                    overflow: hidden;

                    width: 60%;
                    height: 100%;
                }

                .card__image {
                    position: absolute;
                    left: 50%;

                    width: auto;
                    height: 100%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}
