@import "/lib/styles/styleguide/color/color-variables";

.rc-slider-track{
  background-color: $ui-color-blue !important;
}

.rc-slider-handle {
  border-color: $ui-color-blue !important;
  opacity: 1 !important;
}

.rc-tooltip-placement-top {
  padding: 5px 0 !important;
}