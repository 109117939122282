@use "sass:map";

@import "/lib/styles/styleguide/grid/_grid-factory";

.login-form__card-header {
    padding: 0 1.25rem;
    line-height: 1.4;
    font-size: 1.25rem;
}

.login-form__card-body {
    @media (max-width: #{map.get($grid-breakpoints, sm)}) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.login-form__card-footer {
    padding: 0 1.25rem 1.25rem 1.25rem;
}
