@import "../../../styles/styleguide/spacing/spacing-factory";

.hasAccountModal-modalButtonGroup {
    display: flex;
    justify-content: space-between;

    @include margin-top(spacing-4);

    button:last-child {
        @include margin-left(spacing-3);
    }
}
