@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/clamp/clamp";

.store-directions-result {
    &__change-origin {
        padding: 0;
    }

    &__waypoints {
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem;
        border: 1px solid $primary;
        border-radius: 0.25rem;

        &__waypoint {
            display: flex;
            gap: 0.25rem;
            align-items: center;
            flex: 1 1 100%;
            margin-bottom: 0;

            span {
                line-break: anywhere;
                width: 100%;

                @include clamp();
            }

            i,
            img {
                display: block;
            }
        }

        &__direction {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__distance {
        display: flex;
        line-height: 1.6;
        margin: 0.5rem 0 0.5rem 0.25rem;

        &__value {
            font-weight: bold;
            margin-left: 0.5rem;
        }
    }

    &__loader {
        background-color: $ui-color-light-grey;
        display: inline-block;
        border-radius: 0.25rem;
        height: 1.6rem;
        width: 50px;
    }
}
