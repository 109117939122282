.text-underline-hover {
    &:hover {
        text-decoration: underline;
    }
}

.text-decoration-focus-none {
    &:focus {
        text-decoration: none;
    }
}

.text-capitalize-first-char {
    &::first-letter {
        text-transform: capitalize;
    }
}
