@import "../../../../styles/styleguide/color/_color-variables";
@import "../../../../styles/styleguide/normalize/_normalize-variables";
@import "../../../../styles/styleguide/vendor-overrides/bootstrap/custom";

.select-box {
    &__control {
        height: 38px;
        
        &.select-box__control--is-focused {
            box-shadow: $input-focus-box-shadow;
            z-index: 1;
            position: relative;
        }

        .select-box--is-invalid & {
            border-color: $danger;

            &--is-focused {
                border-color: $danger !important;
                box-shadow: 0 0 0 $input-focus-width rgba($danger, 0.25) inset;
            }
        }

        .select-box--is-valid & {
            border-color: $success;

            &--is-focused {
                border-color: $success !important;
                box-shadow: 0 0 0 $input-focus-width rgba($success, 0.25) inset;
            }
        }
    }

    &__placeholder {
        color: $input-placeholder-color !important;
    }

    &__menu {
        z-index: 3 !important;
    }

    &__menu-list {
        hr {
            margin: 0;
        }
    }

    &__single-value {
        .hide-in-control {
            display: none;
        }
    }

    &__option {
        &--is-selected {
            .text-muted {
                color: inherit !important;
            }
        }
    }
}
