@import "/lib/styles/styleguide/card/card";
@import "/lib/styles/styleguide/grid/grid-factory";

.contentResultListHit {
    padding: $card-border-padding--small;
    @include cardInactive();

    @include breakpoint($M) {
        padding: $card-border-padding;
    }

    &:hover {
        @include cardActive;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 120px;
    }
}
