// Style StoreStock
@import "../../../styles/styleguide/color/color-variables";
@import "../../../styles/styleguide/utils";

$storestock-spacer: 5px;

.store-details {
    @include smallHeightDevice {
        :global(.store-picker-context) & {
            padding: 0 !important;
        }
    }

    input {
        margin: 0;
        margin-top: $storestock-spacer;
    }

    h5 {
        margin-bottom: $storestock-spacer;
    }

    p {
        margin: 0;
    }
}

.store-details--disabled {
    opacity: 0.3;
}

.store-details--clickable {
    cursor: pointer;

    @include smallHeightDevice {
        :global(.store-picker-context) & {
            margin-top: 0.5rem;
        }
    }
}

.instock {
    color: $success;
}

.outstock {
    opacity: 1;
    color: $danger;
}

.limitedstock {
    color: $warning;
}

.storeStockRadioButton {
    margin-top: 0.35rem;
}

.storeStockInfo {
    font-weight: 300;
}
