@import "../../../../styles/styleguide/color/color-variables";

@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

.sub-menu-flyout-panel-animate {
    &--in {
        animation: slideIn 0.2s forwards;
    }

    &--out {
        animation: slideOut 0.2s forwards;
    }
}

.sub-menu-flyout-panel-pane {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $ui-color-white;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 3em;
}
