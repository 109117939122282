@import "/lib/styles/styleguide/grid/grid";

$store-search__list-width: 400px;

.store-search-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: auto;
    border-radius: 0.35rem;
    max-height: 100%;

    @include breakpoint(M) {
        width: $store-search__list-width;
    }

    .messages__message-wrapper {
        margin: 0;
    }

    &--skeleton {
        overflow: hidden;
    }
}
