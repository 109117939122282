@import "../../../../styles/styleguide/color/color-variables";
@import "../../../../styles/styleguide/font/font-variables";
@import "../../../../styles/styleguide/grid/grid-factory";

.ecommerce-amount-wrapper {
    min-width: 6.5em !important;
}

.ecommerce-wishlist-button {
    font-size: 1.6rem;
    padding-right: 0;
}

@include breakpoint($M) {
    .ecommerce-amount-wrapper {
        width: auto;
    }
}

@include breakpoint($XL) {
    .ecommerce-wishlist-button {
        font-size: 1rem;
    }
}
