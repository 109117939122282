@import "../../styles/styleguide/spacing/spacing-factory";
@import "../../styles/styleguide/color/color-variables";

$indicator-icon-size: 10px;

.video {
    @include margin-bottom(spacing-4);

    video,
    iframe {
        background-color: $ui-color-primary-black;
    }
}
