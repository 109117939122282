@use "sass:map";

@import "/lib/styles/styleguide/spacing/spacing-factory";
@import "/lib/styles/styleguide/grid/grid-variables";
@import "/lib/styles/styleguide/vendor-overrides/bootstrap/custom";

.contentBlockList {
    min-height: _get-spacing(spacing-10);
    @media (max-width: #{map.get($grid-breakpoints, md)-1}) {
        min-width: _get-spacing(spacing-13);
    }

    &__icon {
        font-size: $font-size-base * 3;
    }
}
