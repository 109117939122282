@import "/lib/styles/styleguide/card/card";
@import "/lib/styles/styleguide/grid/grid-factory";

.contentBlock {
    border: none;

    @include cardInactive();

    img {
        object-fit: cover;
        width: 100%;
    }

    &.contentBlock--image-expanded {
        padding: 0;

        img {
            height: 100%;
            border-radius: 0.25rem;
        }
    }

    &:not(.contentBlock--image-expanded) {
        padding: $card-border-padding--small;
        @include breakpoint($M) {
            padding: $card-border-padding;
        }

        img {
            @include breakpoint($S) {
                aspect-ratio: 1.33;
                margin-bottom: 3rem;
            }
        }
    }

    &:hover {
        @include cardActive;
    }

    &__contents__wrapper {
        display: contents;
    }
}
