@import "../../../styles/styleguide/grid/_grid-factory";
@import "../../../styles/styleguide/color/_color-variables";
@import "../../../styles/styleguide/spacing/_spacing-factory";

.transportServiceModal {
    position: relative;

    &__slider {
        position: relative;

        & > div {
            position: unset;
        }

        &__prev {
            right: 50%;
            margin-right: 45px;
        }

        &__next {
            left: 50%;
            margin-left: 45px;
        }
    }
}

.transportServiceModal-dateTitle {
    margin-bottom: 0;
}

.transportServiceModal-slot {
    padding: 0.5em;
    margin: 0.5em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid $success;
    cursor: pointer;

    input {
        position: relative;
        margin: auto;
        cursor: pointer;
    }
}

.transportServiceModal-disabled {
    border: 0;
    background-color: #c7c7c7;
    color: white;
    cursor: auto;

    input {
        visibility: hidden;
        cursor: auto;
    }
}
