@import "../../../../../styles/styleguide/color/color-variables";

.navigation__mobile-language {
    .language-selector {
        &__toggle {
            color: $ui-color-white;
            text-decoration: none;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
