@import "../../../styles/styleguide/font/font-variables";

.image-list {
    width: 100%;

    &__label {
        &--start {
            text-align: left;
        }

        &--end {
            text-align: right;
        }

        &--center {
            text-align: center;
        }
    }

    &__images {
        margin-top: -8px;

        img {
            margin-top: 8px;
            height: 2em;
        }

        a {
            margin-top: 8px;

            img {
                margin-top: 0;
            }
        }
    }
}
