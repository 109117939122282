@import "/lib/styles/styleguide/color/color-variables";

.store-search-controls {
    padding: 1rem;
    border-radius: 0.35rem;
    background-color: $ui-color-grey;

    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    align-items: start;
    gap: 1rem;

    &--filters-open {
        min-height: 100%;

        .store-search-controls__actions__open-filters-button__arrow {
            transform: rotateZ(180deg);
        }
    }

    & > * {
        width: 100%;
        text-align-last: left;
    }

    &__actions {
        display: flex;
        gap: 1rem;

        &__open-filters-button {
            width: 100%;
            padding: 0.5rem;
            border: none;
            border-radius: 0.35rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__filters {
        height: 100%;
        max-height: 100%;
        flex: 1 1 100%;
        overflow: auto;
        background-color: $ui-color-white;
        border-radius: 0.35rem;
        padding: 0.5rem 0;
    }
}
