.info-block__heading{
  &-button{
    &:hover, &:focus, &:active{
      text-decoration: none;

      h4 {
        text-decoration: underline;
      }
    }
  }
}