@import "../../../../styles/styleguide/color/_color-variables";

.product-comparison-skeleton {
    &__products {
        display: flex;
        padding-left: 20rem;
        overflow: hidden;
    }

    &__product-container {
        width: 20rem;
        min-width: 20rem;
        height: 320px;
    }

    &__product {
        height: 320px;
        background-color: $ui-gray-05;
        margin: 0 1rem;
    }

    &__section {
        width: 100%;
        background-color: $ui-gray-05;
        margin-top: 1rem;
        position: relative;

        &:nth-child(2) {
            height: 300px;
        }

        &:nth-child(3) {
            height: 100px;
        }

        &:nth-child(4) {
            height: 200px;
        }

        &:nth-child(5) {
            height: 150px;
        }

        &:nth-child(6) {
            height: 50px;
        }

        &-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 20rem;
            background-color: $ui-color-light-grey;
            height: 100%;
        }
    }
}
