@use "sass:map";

@import "navigation-component-variables";
@import "/lib/styles/styleguide/_variables";
@import "/lib/styles/styleguide/spacing/spacing-factory";
@import "/lib/styles/styleguide/opacity/opacity-factory";
@import "/lib/styles/styleguide/grid/grid-factory";
@import "/lib/styles/styleguide/corner-radius/corner-radius-factory";
@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/icon/icon-variables";
@import "/lib/styles/styleguide/layer/layer-factory";
@import "/lib/styles/styleguide/positioning/positioning";

@mixin navigation() {
    @media print {
        .navigation {
            display: none;
        }
    }

    .navigation {
        font-size: 1.125rem;
        position: relative;
        min-height: $navigation-height--mobile;
        background-color: $ui-color-white;
        margin-bottom: $navigation-height--search;
        @include z-index(navigation);

        .la-shopping-cart {
            font-size: 2.05em;
            position: relative;

            .header-button__icon--alerts {
                top: -0.5px;
            }
        }

        .la-heart {
            font-size: 1.7em;
        }

        .pl__listitem {
            white-space: nowrap;
            margin: 0 1.5em 0 0;
        }

        &__top-bar {
            @include z-index(navigation, 1);
            position: relative;
        }

        &__checkout {
            min-height: $navigation-height--mobile;
            margin-bottom: 0;
        }

        &__container {
            position: relative;
            height: 100%;
            display: flex;
        }

        &__mobileblock {
            flex: 1;
            display: flex;
            justify-content: center;

            &-logo {
                flex: none;
            }
        }

        &__logo {
            display: inline-block;

            &-link {
                display: block;
                margin-right: 0;
            }

            &-image {
                display: none;

                &--mobile {
                    height: $navigation-logo-height--mobile;
                    display: inline-block;
                }
            }
        }

        &__submenu {
            display: none;
        }

        &__menu {
            position: absolute;
            top: $navigation-height--mobile;
            width: 100%;
            height: calc(100vh - #{$navigation-height--mobile});
            background-color: $ui-color-white;
            overflow-y: auto;
            transition: left 0.3s;

            @include z-index(modal);

            &--mobile {
                left: 100vw;
                display: none;
            }

            &--desktop {
                display: none;
            }

            &.active {
                left: 0;
                display: block;
            }

            &-wrapper {
                list-style: none;

                @include padding(spacing-0);
                @include margin(spacing-0);
            }

            &-item {
                position: relative;
                padding-right: map.get($grid-outer-gutters, xs);
                padding-left: map.get($grid-outer-gutters, xs);
                border-top: $navigation-hamburger-menu-border-style;

                &:last-child {
                    border-bottom: $navigation-hamburger-menu-border-style;
                }

                &-link {
                    color: $text-color;
                    display: block;

                    @include padding-top(spacing-6);
                    @include padding-bottom(spacing-6);

                    &:hover {
                        color: $text-color;
                    }
                }

                .icon {
                    position: absolute;
                    right: map.get($grid-outer-gutters, xs);
                    width: $icon-medium-width;
                    height: $icon-medium-height;

                    @include top(spacing-6);

                    &.collapse {
                        display: none;
                    }
                }

                &.active {
                    .navigation__menu-item-link {
                        font-weight: 700;
                    }

                    .icon.expand {
                        display: none;
                    }

                    .icon.collapse {
                        display: block;
                    }

                    .navigation__submenu {
                        display: block;

                        &-item {
                            @include padding-left(spacing-8);

                            &-link {
                                color: $text-color;
                                display: block;

                                @include padding-top(spacing-6);
                                @include padding-bottom(spacing-6);

                                &:hover {
                                    color: $text-color;
                                }
                            }
                        }
                    }
                }

                &--desktop {
                    display: none;
                }
            }
        }

        &__icons {
            flex: 1;
            justify-content: flex-end;
            display: flex;
            @include padding-top(spacing-4);

            &-icon {
                width: $icon-medium-width;
                height: $icon-medium-height;
                @include margin-left(spacing-7);

                &:first-child {
                    @include padding(spacing-0);
                }

                &--account,
                &--search {
                    display: none;
                }
            }

            .icon {
                width: $icon-medium-width;
                height: $icon-medium-height;
                fill: $ui-color-primary-black;
            }

            &-hamburger {
                height: $icon-medium-height;
                flex-direction: column;
                justify-content: center;
                display: flex;

                @include padding-left(spacing-7);

                &-item {
                    width: $icon-medium-width;
                    height: 2px;
                    background: $ui-color-primary-black;
                    display: inline-block;
                    transform: rotate(0);
                    transition: 0.25s ease-in-out;

                    @include corner-radius(corner-radius-1);
                }

                &-spacer {
                    @include padding-bottom(spacing-2);
                }

                &.active {
                    span:first-child {
                        transform: rotate(45deg) translate(1px, 1px);
                    }

                    span:last-child {
                        transform: rotate(-45deg) translate(1px, 0);
                    }

                    .navigation__icons-hamburger-spacer {
                        display: none;
                    }
                }
            }
        }

        &__section {
            height: 100%;
            display: flex;
            align-items: flex-end;

            &__grey {
                display: none;
                background-color: $ui-color-grey;
                height: 35px;
            }

            &__main {
                height: $navigation-height--mobile;
                width: 100%;
                background-color: $ui-color-blue;
                transition: all 0.22s ease-in-out;
                left: 0;
            }

            &__blue {
                display: none;
                color: $ui-color-white;
                background-color: $ui-color-blue;
                max-height: inherit;

                .pl-component {
                    a {
                        color: $ui-color-white !important;

                        &:hover {
                            color: $ui-color-grey !important;
                        }
                    }
                }

                .header-button__icon {
                    font-size: 1.5rem;
                }
            }

            &__hidden {
                display: none !important;
            }
        }

        &__stickyflyout {
            display: none;
        }

        &__search {
            z-index: 1;
            left: 0;
            position: absolute;
            top: $navigation-height--mobile;
            height: $navigation-height--search;
            width: 100%;
            max-width: 100vw;
            padding: 0.5em 1rem;
            background-color: $ui-color-grey;
            border-bottom: 1px solid $ui-color-light-grey;
        }
    }

    @include breakpoint($S) {
        .navigation {
            &.navigation__checkout {
                margin-bottom: 0;
            }

            &__menu {
                &-item {
                    padding-right: map.get($grid-outer-gutters, sm);
                    padding-left: map.get($grid-outer-gutters, sm);

                    .icon {
                        right: map.get($grid-outer-gutters, sm);
                    }
                }
            }
        }
    }

    @include breakpoint($M) {
        .navigation {
            &__menu {
                &-item {
                    padding-right: map.get($grid-outer-gutters, md);
                    padding-left: map.get($grid-outer-gutters, md);

                    .icon {
                        right: map.get($grid-outer-gutters, md);
                    }
                }
            }
        }
    }

    @include breakpoint($L) {
        body.no-scroll {
            overflow: auto;
        }

        .navigation {
            margin-bottom: 0;

            &__container {
                height: 100%;
                display: flex;
            }

            &__mobileblock {
                flex: 0;
            }

            &__mobilemenu,
            &__mobile-language {
                display: none;
            }

            &__logo {
                align-items: center;
                display: flex;

                &-link {
                    display: block;
                }

                &-image {
                    height: $navigation-logo-height--desktop;
                    display: inline-block;

                    &--mobile {
                        display: none;
                    }
                }
            }

            &__section {
                &__main {
                    height: auto;
                    position: static;
                    background-color: $ui-color-white;

                    &--sticky {
                        width: 100%;
                        position: fixed !important;
                        background-color: $ui-color-blue;
                        transition: all ease 0.5s;
                        left: 0;
                    }
                }

                &__grey {
                    display: block;
                }

                &__blue {
                    display: block;
                }
            }

            &__stickyflyout {
                display: flex;
            }

            &__search {
                position: unset;
                left: auto;
                top: auto;
                height: auto;
                width: auto;
                padding: 0;
                background-color: unset;
                border: none;
            }

            .navigation__menu--mobile {
                display: none;
            }

            &__menu {
                position: relative;
                top: inherit;
                height: 100%;
                flex: 1;
                align-items: flex-end;
                justify-content: center;
                background-color: transparent;
                overflow-y: hidden;

                @include padding-bottom(spacing-6);

                &--desktop {
                    display: flex;
                }

                &-wrapper {
                    display: flex;
                }

                &-item {
                    border: none;

                    @include padding-vertical-horizontal(spacing-0, spacing-5);

                    &-link {
                        display: inline;

                        @include opacity(opacity-6);

                        &:hover {
                            @include opacity(opacity-7);
                        }
                    }

                    &:last-child {
                        border: none;
                    }

                    &--account {
                        display: none;
                    }

                    &--mobile {
                        display: none;
                    }

                    &--desktop {
                        display: list-item;
                    }
                }
            }

            &__submenu {
                display: none;

                &.active {
                    display: none;
                }
            }

            &__icons {
                flex: inherit;
                justify-content: inherit;

                &-icon {
                    @include opacity(opacity-5);

                    &:hover {
                        @include opacity(opacity-7);
                    }

                    &--account,
                    &--search {
                        display: block;
                    }
                }

                &-hamburger {
                    display: none;
                }
            }
        }
    }
}
