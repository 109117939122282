@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/grid/grid";


.hitsPerPage {
    margin: 0 1rem;
    padding: 0 1rem;
    border-left: 1px solid hsl(0, 0%, 80%);
    border-right: 1px solid hsl(0, 0%, 80%);

    @include breakpoint(L) {
        border-left: none;
    }

    &__item {
        color: $text-color;
        background-color: $ui-color-white;
        border: none;
        text-underline-offset: 0.3rem;

        &:focus, &:hover, &--refined {
            text-decoration: underline;
        }
    }

    &__item + &__item {
        padding-left: 0.5rem;
    }
}
