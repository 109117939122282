@import "/lib/styles/styleguide/color/color-variables";

$colorswatchSize: 40px;
$colorswatchItemSpacing: 3px;

.colorswatch {
    transform: translateX(-$colorswatchItemSpacing);
    padding-top: 10px;

    &__item {
        width: $colorswatchSize;
        height: $colorswatchSize;
        padding: 3px;
        margin: $colorswatchItemSpacing;
        border: 2px solid $ui-color-disabled-font-color;
        border-radius: 5px;
        transition: all 50ms ease-out;
        background-color: transparent;

        &--active {
            border-color: $secondary-link-color;
            border-width: 3px;
            padding: 2px;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 2px;
        }
    }
}
