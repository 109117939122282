@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/corner-radius/corner-radius";
@import "/lib/styles/styleguide/layer/layer";
@import "/lib/components/Search/SearchAutocomplete/searchAutocomplete-variables";

.searchBox {
    height: $searchBox__height;
    background-color: $primary;

    @include z-index(modal, 1);
    @include border-radius(0.25rem, all);

    &__wrapper {
        transition: outline-offset 100ms ease-in-out, outline 100ms ease-in-out !important;
        outline: 0 solid $ui-color-searchbox-focus;
        outline-offset: 0;
        min-width: 0;

        > * {
            background-color: $ui-color-grey;
        }

        > input {
            border-radius: 0;
            min-width: 0;

            &[type="search"]::-webkit-search-cancel-button {
                -webkit-appearance: none;
            }
        }

        &:focus-within {
            outline: 3px solid $ui-color-searchbox-focus !important;
            outline-offset: -3px;
        }
    }

    &__button {
        &--reset {
            text-transform: capitalize;
            color: $ui-color-disabled-font-color;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    &__close {
        background-color: $ui-color-white;
        color: $primary;
        border: none;
        min-height: $searchBox__height;
        width: $searchBox__height;
        margin-right: 1rem;
        transition: background-color 0.1s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;

        @include border-radius(0.25rem, all);

        &:hover {
            background-color: $ui-color-grey;
        }

        &:active,
        &:focus {
            background-color: $ui-color-grey;
            border: 1px solid $primary;
        }
    }
}
