@use "sass:map";

.form-control {
    &--focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: $input-box-shadow, $input-focus-box-shadow;
        } @else {
            box-shadow: $input-focus-box-shadow;
        }
    }
}

@each $state, $data in $form-validation-states {
    $color: map.get($data, color);

    .form-control {
        &--focus,
        &:focus {
            .was-validated &:#{$state},
            &.is-#{$state} {
                border-color: $color;
                box-shadow: inset 0 0 0 $input-focus-width rgba($color, 0.25);
            }

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }
        }
    }
}
