@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/font/font-variables";

.delivery-item {
    &__wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__description {
        font-size: 0.85em;
        font-weight: 300;
        margin: 0;
    }

    &__cost {
        white-space: nowrap;
    }

    &__serviceProduct {
        margin-left: 1.3rem;
    }
}
