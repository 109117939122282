@use "sass:map";

@import "/lib/styles/styleguide/grid/_grid-factory";
@import "/lib/styles/styleguide/color/_color-variables";
@import "/lib/styles/styleguide/layer/layer";
@import "/lib/styles/styleguide/variables";

@mixin comparison-bar--mobile {
    @media (max-width: #{map.get($grid-breakpoints, md)}), (max-height: 460px) {
        @content;
    }
}

$comparison-bar--min-height: 160px;

.product-comparison-bar__container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    @include z-index(overlay);

    &--open {
        @include z-index(navigation, 2);
    }

    .product-comparison-bar__button {
        @include comparison-bar--mobile {
            width: 100%;
        }
    }

    &--sticky-order-button {
        .product-comparison-bar__button {
            @include comparison-bar--mobile {
                width: 50%;
            }
        }
    }

    .modal-backdrop {
        background-color: $ui-color-overlay-black;
        @include z-index(overlay);

        &.show {
            pointer-events: all;
            opacity: 1;
        }
    }
}

.product-comparison-bar {
    pointer-events: all;
    position: fixed;
    bottom: 0;
    transform: translateY(100%);
    transition: all 200ms ease-in-out;
    right: 2rem;
    max-height: 100vh;
    min-width: 350px;
    @include z-index(overlay, 2);

    @include comparison-bar--mobile {
        left: 0;
        right: 0;

        .product-comparison-bar__category-name,
        .product-comparison-bar__button__badge {
            display: none;
        }
    }

    &--open {
        transform: translateY(0%);

        @include comparison-bar--mobile {
            .product-comparison-bar__button {
                width: 100%;
                left: 0;
                padding: 0;

                button {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            .product-comparison-bar__category-name,
            .product-comparison-bar__button__badge {
                display: inline-block;
            }
        }
    }

    &__button {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-100%);

        @include comparison-bar--mobile {
            padding: 0.5rem;

            button {
                padding: 0.5rem !important;
                line-height: 1.5;
                border-radius: 0.3rem;
                justify-content: center;
            }

            &__badge {
                margin-left: auto;
            }
        }

        button {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &:focus {
                box-shadow: none !important;
            }

            @include comparison-bar--mobile {
                width: 100%;
                display: flex;
                align-items: center;
                border-bottom-left-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }
        }
    }

    &__products {
        background-color: $ui-color-white;
        width: 400px;
        border: 1px solid $ui-color-light-grey;
        border-bottom-width: 0;
        padding: 1rem 0;
        border-radius: 0.25em 0 0 0;

        @include comparison-bar--mobile {
            border-radius: 0;
            width: 100%;
        }

        &__wrapper {
            overflow: auto;
            min-height: $comparison-bar--min-height;
            max-height: calc(100vh - 19.5rem);
        }
    }

    &__product {
        margin: 1rem;

        &__button {
            margin: 6px;
            aspect-ratio: 1 / 1;
            top: 0;
            right: 0;
            z-index: 1;

            @media (hover: hover) {
                &:hover {
                    background-color: $ui-color-grey;
                }
            }
        }
    }

    &__compareProducts {
        margin: 0 1rem;
    }
}
