.list-ul,
.rich-text-content ul {
    list-style-type: none !important;
    margin-bottom: 24px;
    padding: 0 0 0 1.5em !important;

    li,
    .list-ul__item {
        margin-bottom: 0 !important;

        &:has(a) {
            margin: 0.5rem 0;
        }

        &::before {
            content: "\25A0";
            color: $primary;
            display: inline-block;
            margin-left: -1.5em;
            padding-right: 0.8em;
            transform: translateY(-0.2em);
        }

        &.current {
            margin-left: -1.5em;

            &::before {
                margin-right: 0.2em;
                color: white;
                margin-left: 0;
            }
        }
    }
}
