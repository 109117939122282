@import "../../../../styles/styleguide/color/_color-variables";
@import "../../../../styles/styleguide/normalize/_normalize-variables";
@import "../../../../styles/styleguide/vendor-overrides/bootstrap/custom";

.creatable-select-box {
    &__control {
        height: 38px;
    
        &.creatable-select-box__control--is-focused {
            box-shadow: $input-focus-box-shadow;
            z-index: 1;
            position: relative;
        }
        
        .select-box--is-invalid & {
            border-color: $danger;

            &--is-focused {
                border-color: $danger !important;
                box-shadow: 0 0 0 $input-focus-width rgba($danger, 0.25) inset;
            }
        }

        .select-box--is-valid & {
            border-color: $success;

            &--is-focused {
                border-color: $success !important;
                box-shadow: 0 0 0 $input-focus-width rgba($success, 0.25) inset;
            }
        }
    }

    &__value-container {
        padding: 0 !important;
    }

    &__placeholder {
        color: $input-placeholder-color !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    &__menu-list {
        hr {
            margin: 0;
        }
    }

    &__single-value {
        .hide-in-control {
            display: none;
        }
    }

    &__option {
        &--is-selected {
            .text-muted {
                color: inherit !important;
            }
        }
    }

    &__input {
        grid-area: 1 / 2 / auto / span 3 !important;
        padding: 4px 10px !important;
    }

    &__input-container {
        padding: 0 !important;
    }
}
