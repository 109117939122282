@import "../../styles/styleguide/grid/grid-factory";

.grid-container {
    display: flex;
    flex-direction: column;

    .grid-item {
        & + .grid-item {
            margin-top: 15px;
        }

        &.grid-item__accessories {
            margin-top: 0px;
        }
    }

    @include breakpoint($M) {
        display: grid;
        align-items: start;

        .grid-item {
            & + .grid-item {
                margin-top: 0;
            }
        }

        &__pdp {
            grid-template-rows: auto;
            gap: 15px;
            grid-template-columns: repeat(2, minmax(10px, 1fr));
        }

        .grid-item {
            &__product-image {
                grid-area: 1 / 1 / 2 / 2;
            }

            &__product-product-compact {
                grid-area: 2 / 1 / 3 / 2;
            }

            &__product-info {
                grid-area: 3 / 1 / 4 / 2;
            }

            &__relatedProducts {
                margin-top: -15px !important;
                grid-area: 4 / 1 / 5 / 2;
            }

            &__ecommerce {
                grid-area: 1 / 2 / span 5 / 3;

                &.sticky {
                    position: sticky;
                    top: 1.5rem;
                }
            }

            &__info {
                grid-area: 2 / 2 / 3 / 3;
            }

            &__frequently-bought-together {
                grid-area: 5 / 1 / 6 / 2;
            }
        }
    }

    @include breakpoint($L) {
        &__pdp {
            grid-template-columns: repeat(3, minmax(10px, 1fr));
        }

        .grid-item {
            &__product-image {
                grid-area: 1 / 1 / 2 / 2;
            }

            &__product-product-compact {
                grid-area: 1 / 2/ 2 / 3;
            }

            &__ecommerce {
                grid-area: 1 / 3 / span 5 / 4;
            }

            &__relatedProducts {
                grid-area: 2 / 1 /3 / 3;
            }

            &__info {
                grid-area: 2 / 3 / 3 / 4;
            }

            &__product-info {
                grid-area: 4 / 1 / 5 / 3;
            }

            &__frequently-bought-together {
                grid-area: 3 / 1 / 4 / 3;
            }
        }
    }
}
