@import "/lib/styles/styleguide/clamp/clamp";

.search-item {
    @include clamp();

    &__label {
        font-weight: bold;

        > span {
            font-weight: normal;
        }
    }
}
