@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/layer/layer";
@import "/lib/styles/styleguide/grid/grid";

.backToTop {
    position: fixed;
    right: 0.75rem;
    bottom: 0.5rem;
    transition: opacity 200ms ease-in-out;
    @include z-index(overlay);

    @include breakpoint(M) {
        right: 2rem;
        bottom: 2rem;
    }

    &--visible {
        opacity: 1;
    }

    &--hidden {
        opacity: 0;
    }

    &--bottomControlsVisible {
        bottom: 4rem;

        @include breakpoint(M) {
            bottom: 2rem;
        }
    }
}
