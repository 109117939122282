@import "/lib/styles/styleguide/grid/grid";

$resultList__gap: 20px;

.resultList {
    display: grid;
    position: relative;
    gap: $resultList__gap;
    //margin-bottom: $resultList__gap;

    grid-template-columns: repeat(1, 1fr);

    @include breakpoint(S) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(M) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint(XL) {
        grid-template-columns: repeat(4, 1fr);
    }
}
