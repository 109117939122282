@import "/lib/styles/styleguide/color/color-variables";

.pagination {
    display: flex;
    flex-direction: column;

    > * {
        display: flex;
        flex-direction: column;
        text-align: center;
        place-self: center;
        position: relative;
    }

    &__progress-bar {
        border: none;
        height: 4px;
        border-radius: 2px;

        // Value
        color: $primary;
        &::-moz-progress-bar {
            background-color: $primary;
        }
        &::-webkit-progress-value {
            background-color: $primary;
        }

        // Background
        background-color: $ui-color-light-grey;
        &::-webkit-progress-bar {
            background-color: $ui-color-light-grey;
        }
    }
}
