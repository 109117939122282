@use "sass:color";
@import "../../../../styles/styleguide/color/color-variables";
@import "../../../../styles/styleguide/shadow/shadow-factory.scss";
@import "/lib/styles/styleguide/card/card";

.selectable-card-field {
    @include cardFactory("all");
    border: 1px solid transparent;

    &:hover,
    &.active {
        border: 1px solid $ui-color-light-grey;
        border-color: color.adjust($ui-color-light-grey, $lightness: -10%);
        @include generate-shadow(shadow-1);
    }

    &:hover {
        cursor: pointer;
    }

    &.active {
        cursor: auto !important;
    }

    &_label {
        &:hover:not(.active) {
            cursor: pointer;
        }
    }
}
