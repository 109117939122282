.faq-accordion {
    &__question {
        &__header {
            border-color: gray;

            h4 {
                justify-content: space-between;
                cursor: pointer;

                i {
                    color: gray;
                }
            }
        }
    }
}
