@use "sass:map";

$enable-grid-classes: true !default;
$grid-columns: 12 !default;
$grid-gutter-width: 2.8%;
$grid-max-width: 1440px;

//  When changing these breakpoints also change the breakpoints in the templates and template-types.
//  In the templates and template-types always take the value from the next breakpoint and subtrack 1.
//  e.g. :  xs = 600
//          s = 768
//          ...

$grid-breakpoints: (
    xxs: 0,
    xs: 360px,
    sm: 480px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
) !default;

$container-max-widths: (
    xs: map.get($grid-breakpoints, xs) - 1,
    sm: map.get($grid-breakpoints, md) - 1,
    md: map.get($grid-breakpoints, lg) - 1,
    lg: $grid-max-width,
) !default;

$grid-outer-gutters: (
    xxs: 1.4%,
    xs: 1.4%,
    sm: 1.4%,
    md: 1.4%,
    lg: 1.4%,
    xl: 1.4%,
) !default;

// breakpoint shorthands
$XXS: XXS;
$XS: XS;
$S: S;
$M: M;
$L: L;
$XL: XL;
