@import "../vendor-overrides/bootstrap/custom";

.form-group {
    small {
        p {
            font-size: 100% !important;
        }
    }
}

input,
textarea,
select {
    &.form-control.is-valid {
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            background-image: ($form-feedback-icon-valid) !important;
            box-shadow: 0 0 0 1000px #fff inset !important;
            transition: background-color 5000s ease-in-out 0s;
        }
    }
}

/* Hide numeric input control */
input[type="number"] {
    -moz-appearance: textfield;
}

input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
