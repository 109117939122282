@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/grid/_grid-factory";
@import "/lib/styles/styleguide/spacing/_spacing-factory";

$store-search-height: 80vh;
$store-search-max-height: 800px;

.store-search {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: $store-search-height;
    max-height: $store-search-max-height;
    display: flex;
    border-radius: 0.35rem;

    &--sm {
        flex-direction: column;
    }

    &--md {
        gap: 0.5rem;

        &__map {
            width: 100%;
        }
    }

    &__tabs {
        height: 100%;
        display: flex;
        overflow: hidden;

        & > * {
            transition: transform 150ms ease-in-out;
        }

        &--list-active {
            & > * {
                transform: translateX(0);
            }
        }

        &--map-active {
            & > * {
                transform: translateX(-100%);
            }
        }
    }

    &__tab {
        min-width: 100%;
        min-height: 100%;
    }

    &__list {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &--skeleton {
        background-color: $ui-color-grey;
    }
}
