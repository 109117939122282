@import "../../../../../styles/styleguide/color/color-variables";

.swiper-nav-button {
    color: $primary;
    cursor: pointer;
    font-size: 1.8em;
}

.swiper-nav-button--disabled {
    cursor: default;
    opacity: 0.2;
    color: $primary;
}
