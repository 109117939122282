@import "../../../styles/styleguide/color/_color-variables";

@import "bootstrap/scss/functions"; // bootstrap functions need to be included to avoid errors
@import "bootstrap/scss/variables"; // bootstrap variables necessary for SASS

.bonusCardBalance {
    font-weight: $font-weight-bold;
    color: $danger;
    font-size: $font-size-lg;
    line-height: $font-size-lg;
}
