$productTitle__lineHeight: 1.2rem;

.productTitle {
    line-height: $productTitle__lineHeight;

    @each $line in (1, 2, 3, 4, 5) {
        &-#{$line} {
            height: $productTitle__lineHeight * $line;
        }
    }
}
