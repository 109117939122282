@use "sass:math";

@media print {
    @for $i from 1 through $grid-columns {
        $width: #{percentage(math.div($i, $grid-columns))};
        .col-print-#{$i} {
            max-width: $width;
            flex: 0 0 $width;
        }
    }

    .col-print {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
}
