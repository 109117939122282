@use "sass:map";

@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/spacing/spacing-variables";
@import "/lib/styles/styleguide/button/button-factory";
@import "/lib/styles/styleguide/grid/_grid-factory";

@include _generate-button-class(
    500,
    spacing-5,
    spacing-5,
    $ui-color-white,
    $identity-color-attention,
    $identity-color-attention--hover,
    $identity-color-attention--active,
    spacing-6,
    spacing-3,
    spacing-2,
    spacing-0
);

.button-component {
    &--full-width-mobile {
        width: 100%;
        @media (min-width: #{map.get($grid-breakpoints, md)}) {
            width: auto;
        }
    }

    &--transparent {
        border: none;
        background-color: transparent;

        &:hover {
            background-color: $ui-color-light-grey;
            border-color: $ui-color-light-grey;
        }
    }
}
