//###################################
//####                           ####
//####    Overrides variables    ####
//####                           ####
//###################################
@import "./../../color/color-variables";

$arrowHeight: 44px;

.swiper {
    &-container-vertical {
        height: 100%;
    }

    &-pagination {
        text-align: center;
    }

    &-button {
        color: $ui-color-blue;
        cursor: pointer;
        font-size: 1.8em;
        height: $arrowHeight;
        margin-top: calc(-1 * #{$arrowHeight} / 2);
        position: absolute;
        top: 50%;
        z-index: 1;

        &-prev {
            left: 0;
        }
        &-next {
            right: 0;
        }
        &-disabled {
            opacity: 0.15;
        }
    }
}
