@import "/lib/styles/styleguide/color/color-variables";

.additionalService {
    &__description,
    &__documents {
        font-size: 0.85em;
        font-weight: 300;
        margin: 0;
    }

    &--disabled {
        color: $ui-color-disabled-font-color;
    }
}
