@import "../../../../../styles/styleguide/color/color-variables";

.swatches {
    width: 32px;
    height: 32px;
    border: 1px solid grey;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    &--active {
        border: 2px solid $primary;
        font-weight: bold;
    }

    &--stripes {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAKElEQVQYV2NkQAP9/f3/GZHFQAKFhYWMcEGYAEgRWBBZACyILgASBACTBxKyRBa+6AAAAABJRU5ErkJggg==)
            repeat;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    img {
        width: 100%;
        padding: 2px;
    }
}
