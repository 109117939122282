@import "../../../../styles/styleguide/color/color-variables";

.password-field {
    position: relative;

    &__hint {
        position: absolute;
        top: 0.4em;
        background-color: $ui-color-grey;
        padding: 0.3em 1em;
        border-radius: 0.2em;
        right: 0.4em;
        font-size: 0.8em;
        &:hover {
            cursor: pointer;
        }

        &--padded {
            right: 2.5em;
        }
    }
}
