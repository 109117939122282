@import "/lib/styles/styleguide/color/color-variables";

.feature-kind {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border-bottom: 1px solid $primary;
    font-weight: bold;

    &__wrapper {
        position: absolute;
        height: 3rem;
        margin-top: 1rem;
    }
}
