@use "sass:map";

@import "/lib/styles/styleguide/vendor-overrides/swiper/_swiperLayout";
@import "/lib/styles/styleguide/grid/_grid-factory";

$category-slide_padding: 10px;
$category-img_width: 240px;
$category-img_height: 180px;

.category-slider-nav-button {
    margin: auto 0;

    > i {
        transform: translateY(-50%);
    }
}

.categorySlider-slide {
    user-select: none;

    @media (min-width: #{map.get($grid-breakpoints, md)}) {
        padding: 0 $category-slide_padding;
    }
}

@media (max-width: #{map.get($grid-breakpoints, md)}) {
    .categorySlider-slide {
        .aspect-ratio-box {
            height: 0;
            overflow: hidden;
            padding-top: calc(
                $category-img_height / $category-img_width * 100%
            );
            position: relative;
        }

        &--image-wrapper {
            border-radius: 50%;
            overflow: hidden;
            margin: 0 12.5%;
            padding-top: 12.5%;
            background-color: #e0f3ff;
            position: absolute;
            top: 0;
            bottom: 0;
        }
    }
}
