@import "/lib/styles/styleguide/color/color-variables";

.product-layout-description {
    &__list {
        font-size: 0.875em;
        line-height: 1.313em;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;

        li {
            padding: 0.15em 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow-y: hidden;
        }
    }
}
