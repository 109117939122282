@use "sass:map";

@import "/lib/styles/styleguide/spacing/_spacing-factory";
@import "/lib/styles/styleguide/grid/_grid-variables";
@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/utils";

@media print {
    .breadcrumb {
        display: none;
    }
}

.breadcrumb-list {
    position: relative;

    .breadcrumb {
        background: none;

        &-item {
            position: relative;

            a {
                color: $secondary-link-color;
            }

            a:hover {
                color: $secondary-link-color-hover;
                text-decoration: underline;
            }

            &::before {
                font-family: Line Awesome Free, sans-serif;
                font-weight: 600;
            }

            @media (max-width: #{map.get($grid-breakpoints, lg)-1}) {
                appearance: none;
                display: flex;
                flex: 0 0 auto;
                flex-direction: row;
                &:first-child {
                    z-index: 2;
                }
                &:last-child {
                    z-index: 2;
                }
            }
            @media (max-width: #{map.get($grid-breakpoints, md)-1}) {
                &:nth-last-child(2) {
                    z-index: 2;
                }
            }
        }

        @media (max-width: #{map.get($grid-breakpoints, lg)-1}) {
            position: relative;
            flex-wrap: nowrap;
            overflow-x: scroll;
            -ms-overflow-style: none; /* Remove scrollbar for Internet Explorer, Edge */
            scrollbar-width: none; /* Remove scrollbar for Firefox */

            &::-webkit-scrollbar {
                display: none; /* Remove scrollbar for Chrome, Safari, and Opera */
            }
        }
    }

    @media (max-width: #{map.get($grid-breakpoints, lg)-1}) {
        &::before,
        &::after {
            content: "";
            display: block;
            width: 1.2rem;
            height: 2.4rem;
            position: absolute;
            top: 0;
            z-index: 1;
        }

        &::before {
            background: linear-gradient(
                90deg,
                rgba($ui-color-white, 1) 50%,
                rgba($ui-color-white, 0) 100%
            );
            left: 0;
        }

        &::after {
            background: linear-gradient(
                270deg,
                rgba($ui-color-white, 1) 50%,
                rgba($ui-color-white, 0) 100%
            );
            right: 0;
        }
    }
}
