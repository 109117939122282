@import "/lib/styles/styleguide/color/color-variables";

$store-directions-height: 60vh;
$store-directions-max-height: 500px;

$store-directions-controls-width: 100%;
$store-directions-controls-max-width: 400px;

.store-directions {
    height: $store-directions-height;
    max-height: $store-directions-max-height;
    position: relative;
    border-radius: 0.35rem;
    background-color: $ui-color-grey;

    &__controls {
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: calc($store-directions-controls-width - 2rem);
        max-width: $store-directions-controls-max-width;
        background-color: $ui-color-white;
        border-radius: 0.35rem;
        padding: 1rem;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    }
}
