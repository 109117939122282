@import "/lib/styles/styleguide/card/card";
@import "/lib/styles/styleguide/grid/grid";

.sort {
    flex: 0 0 auto;
    box-shadow: $card-shadow;
    border-radius: 0.25rem !important;

    @include breakpoint(S) {
        flex: none;
        width: 200px !important;
    }

    .select-box__control {
        border: none;
    }
}
