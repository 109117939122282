@import "../../../styles/styleguide/color/color-variables";
@import "../../../styles/styleguide/utils";

.divider {
    display: block;
    width: 100%;
    border-bottom: 1px solid $ui-color-divider;

    @include smallHeightDevice {
        .store-picker-context & {
            margin-top: 0.3rem !important;
        }
    }
}
