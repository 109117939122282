@use "sass:map";

@import "/lib/styles/styleguide/grid/_grid-factory";
@import "/lib/styles/styleguide/color/_color-variables";
@import "/lib/styles/styleguide/spacing/_spacing-factory";

.storeList {
    border: 1px solid $ui-color-light-grey;
    padding: 1em;
    margin-bottom: 0.75em;

    &:hover {
        background-color: $ui-color-grey;
        cursor: pointer;
    }
}

.storeList-icon {
    background-color: $ui-color-light-blue;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2em;
    width: 2em;
    font-size: 1.5em;
}

.storeList-list {
    overflow: auto;

    @media (min-width: #{map.get($grid-breakpoints, md)}) {
        max-height: 700px;
    }
}
