$products__height--xs: 323px;
$products__height--md: 380px;

$products__height--xs--pinned: calc($products__height--xs - 3rem);
$products__height--md--pinned: calc($products__height--md - 3rem);

$product-tile__width--xs: 12.5rem;
$product-tile__width--md: 17.5rem;

$actions__height: 4rem;
