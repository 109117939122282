@import "../../../../../styles/styleguide/color/color-variables";

.dropdown-fallback__dropdown {
    color: $ui-color-primary-black;
    background-color: $ui-color-light-grey;
    border-color: $ui-color-grey;
    justify-content: space-between;
    align-items: center;
    text-indent: 0.75rem;
}
