$opacity-values: (
    100: 1,
    90: 0.9,
    80: 0.8,
    70: 0.7,
    60: 0.6,
    50: 0.5,
    40: 0.4,
    30: 0.3,
);

@each $key, $value in $opacity-values {
    .opacity-#{$key} {
        opacity: $value;
    }
}
