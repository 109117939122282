@use "sass:math";
@import "/lib/components/Search/SearchResults/search";

$facet__title__spacing: 10px;

.facet {
    width: 100%;

    &:not(:first-child) {
        padding-top: $search__group__gap;
        margin-top: $search__group__gap;
        border-top: 1px solid $ui-color-divider;
    }

    h3 + * {
        > *:first-child {
            > *:first-child {
                padding-top: $facet__title__spacing;
            }
        }
    }

    i {
        transition: all 200ms ease-out;
        transform-origin: center;
        transform: rotateZ(0deg);
    }

    .facet__items {
        display: grid;
        grid-template-rows: 1fr;
        transition: all 200ms ease-out;

        > div {
            overflow: hidden;

            > ul:not(.colorswatch),
            > button {
                line-height: 1.9;
                @include breakpoint(S) {
                    line-height: 1.7;
                }
            }
        }
    }

    &--collapsed {
        & > h3 > i {
            transform: rotateZ(180deg);
        }

        .facet__items {
            grid-template-rows: 0fr;
            opacity: 0;
        }
    }
}

.facet,
.facet button {
    font-size: 0.95rem;
}
