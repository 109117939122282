@use "sass:map";

@import "../color/color-factory";

// section-component theme
@mixin container-theme($theme) {
    color: map.get($theme, "color");
    background-color: map.get($theme, "background-color");

    @if (map.get($theme, "container-color")) {
        .section-component__container,
        .section-component__container--fluid {
            background-color: map.get($theme, "container-color");
        }
    }

    @if (map.get($theme, "heading-color")) {
        .heading-1,
        .heading-2,
        .heading-3,
        .heading-4,
        .heading-5,
        .heading-6 {
            color: map.get($theme, "heading-color");
        }
    }

    .icon:not(.icon.unthemeable),
    .icon:not(.link .icon) {
        fill: map.get($theme, "color");
    }

    .list--bullet {
        li {
            &::before {
                background-color: map.get($theme, "color");
            }
        }
    }
}

// button-component-theme
@mixin button-theme($theme) {
    border-color: map.get($theme, "border-color");
    color: map.get($theme, "color");
    background-color: map.get($theme, "background-color");

    &:hover {
        border-color: map.get($theme, "border-color--hover");
        color: map.get($theme, "color--hover");
        background-color: map.get($theme, "background-color--hover");
    }

    &:focus {
        border-color: map.get($theme, "border-color--focus");
        color: map.get($theme, "color--focus");
        background-color: map.get($theme, "background-color--focus");
    }

    &:active {
        border-color: map.get($theme, "border-color--active");
        color: map.get($theme, "color--active");
        background-color: map.get($theme, "background-color--active");
    }
}

// section-component theme gradient
@mixin gradient-theme($theme) {
    color: map.get($theme, "color");
    background-color: map.get($theme, "background-color");

    .section-component__container,
    .section-component__container--fluid {
        @include linear-gradient-background(
            map.get($theme, "gradient-color-start"),
            map.get($theme, "gradient-color-end")
        );
    }

    .icon:not(.icon.unthemeable),
    .icon:not(.link .icon) {
        fill: map.get($theme, "color");
    }

    .list--bullet {
        li {
            &::before {
                background-color: map.get($theme, "color");
            }
        }
    }
}
