@import "layer-factory";
@import "layer-variables";

@each $layer, $zIndex in $layers {
    .layer-#{$layer} {
        z-index: $zIndex;
    }

    .layer-#{$layer}-1 {
        z-index: $zIndex + 1;
    }
}
