@use "sass:map";

@import "/lib/styles/styleguide/grid/_grid-factory";
@import "/lib/styles/styleguide/_variables";
@import "/lib/styles/styleguide/spacing/_spacing";
@import "/lib/styles/styleguide/color/_color-variables";

.info-block {
    @media (max-width: #{map.get($grid-breakpoints, lg)-1}) {
        border-top: 1px solid $ui-gray-04;
        border-bottom: 1px solid $ui-gray-04;
        margin-top: -1px;
    }

    &__heading {
        @media (min-width: #{map.get($grid-breakpoints, lg)}) {
            display: none;
        }

        .la {
            &-minus,
            &-angle-up {
                display: none;
            }

            &-plus,
            &-angle-down {
                display: inline;
            }
        }
    }

    &__content {
        @media (max-width: #{map.get($grid-breakpoints, lg)-1}) {
            overflow: hidden;
            max-height: 0;

            .title {
                display: none;
            }
        }
    }

    &--active {
        .la {
            &-minus,
            &-angle-up {
                display: inline;
            }

            &-plus,
            &-angle-down {
                display: none;
            }
        }

        .info-block__content {
            display: block;
            max-height: 10000px;
            transition: max-height 0.7s ease-in-out;
        }
    }
}

.product-info-nav {
    background-color: white;
    border-bottom: 1px solid $ui-gray-04;
    position: sticky;
    top: 0;
    z-index: 2;
    @include margin-vertical(spacing-7);

    @media (max-width: #{map.get($grid-breakpoints, lg)-1}) {
        display: none;
    }

    .nav-link {
        border-bottom: 3px solid transparent;
        padding: 0.5rem 1rem !important;

        &--active {
            border-bottom: 3px solid $primary;
        }
    }
}
