.comparizon,
.expandable-circle,
.right-side-panel,
.panel-content,
.sidepanel-button-container,
#pj-bar,
#pj-bar-under,
#expandable-circle,
#right-side-panel {
    display: none !important;

    * {
        display: none !important;
    }
}
