@import "../color/color-variables";

$icon-default-width: 32px !default;
$icon-default-height: 32px !default;
$icon-small-width: 16px !default;
$icon-small-height: 16px !default;
$icon-medium-width: 24px !default;
$icon-medium-height: 24px !default;
$icon-large-width: 40px !default;
$icon-large-height: 40px !default;
$icon-xlarge-width: 48px !default;
$icon-xlarge-height: 48px !default;
$icon--default--fill: $identity-color-success !default;
