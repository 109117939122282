@import "/lib/styles/styleguide/layer/layer";

$messagesContainer__max-width: 350px;

.wishlistHeaderButton {
    &__messages {
        max-width: $messagesContainer__max-width;
        @include z-index(overlay);

        bottom: 0;
        left: 0;
    }
}
