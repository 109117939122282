@use "sass:map";

@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/corner-radius/corner-radius";
@import "/lib/styles/styleguide/grid/grid-factory";
@import "/lib/styles/styleguide/layer/layer";
@import "/lib/components/Search/SearchAutocomplete/searchAutocomplete-variables";

.autocompleteSearchBox {
    &:not(.autocompleteSearchBox--open) {
        @include border-radius(0.25rem, all);
    }

    &:focus-visible {
        .searchBox__wrapper {
            outline: 3px solid $ui-color-searchbox-focus !important;
            outline-offset: -3px;
        }
    }

    &--open {
        @include z-index(navigation, 1);

        background-color: $ui-color-white;
        padding: 1rem;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        @include breakpoint(L) {
            top: 33px;
            width: calc(
                map.get($grid-breakpoints, lg) - 4rem - ($searchBox__height * 2)
            );
            height: auto;
            border-radius: 0.5rem;
            left: calc(
                50% -
                    calc(
                        map.get($grid-breakpoints, lg) - 4rem -
                            ($searchBox__height * 2)
                    ) / 2
            );
        }

        @include breakpoint(XL) {
            width: calc(
                map.get($grid-breakpoints, lg) - ($searchBox__height * 2) - 1rem
            );
            left: initial;
            transform: translateX(-1rem);
        }

        > .autocompleteSearchBox__close {
            position: absolute;
            width: $searchBox__height;
            height: $searchBox__height;
            top: 1rem;
            right: calc(-1rem - $searchBox__height);
            background-color: $ui-color-white;
        }
    }

    &__overlay {
        @include z-index(navigation);
        background-color: $ui-color-overlay-black;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    input {
        outline: none !important;
    }
}
