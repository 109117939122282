// Line-Awesome
// @link: https://icons8.com/line-awesome
@import "../styles/styleguide/vendor-overrides/line-awesome/custom";
@import "line-awesome/dist/line-awesome/scss/line-awesome";

@import "./styleguide/vendor-overrides/swiper/custom";
@import "swiper/swiper";
@import "swiper/modules/a11y/a11y";
@import "swiper/modules/controller/controller";
// @import "swiper/modules/lazy/lazy";
@import "swiper/modules/navigation/navigation";
@import "swiper/modules/pagination/pagination";
@import "swiper/modules/scrollbar/scrollbar";
@import "swiper/modules/thumbs/thumbs";
@import "swiper/modules/zoom/zoom";

// Bootstrap
// @link: https://getbootstrap.com/docs/
@import "../styles/styleguide/vendor-overrides/bootstrap/custom";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/tables";
@import "./styleguide/vendor-overrides/bootstrap/custom";
@import "../styles/styleguide/vendor-overrides/bootstrap/form";
@import "../styles/styleguide/vendor-overrides/bootstrap/buttons";
@import "../styles/styleguide/vendor-overrides/bootstrap/border";
@import "../styles/styleguide/vendor-overrides/bootstrap/list";
@import "../styles/styleguide/vendor-overrides/bootstrap/opacity";
@import "../styles/styleguide/vendor-overrides/bootstrap/background";
@import "../styles/styleguide/vendor-overrides/bootstrap/print";
@import "../styles/styleguide/vendor-overrides/bootstrap/modal";
@import "../styles/styleguide/vendor-overrides/bootstrap/display";

@import "../styles/styleguide/index";

@import "./styleguide/vendor-overrides/flip/custom";
@import "@pqina/flip/dist/flip.min.css";
