@import "normalize-factory";
@import "normalize-variables";

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.xf-content-height {
    margin: 0 !important;
}

.img-responsive {
    max-width: 100%;
    display: block;
}

p {
    word-break: break-word;
}

body.no-scroll {
    overflow: hidden;
}

textarea::placeholder,
input::placeholder {
    color: $input-placeholder-color !important;
}
