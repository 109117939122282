@import "../../styles/styleguide/color/_color-variables";
@import "../../styles/styleguide/shadow/shadow-factory.scss";
@import "../../styles/styleguide/spacing/spacing-factory";
@import "../../styles/styleguide/grid/grid-factory";
@import "../../styles/styleguide/positioning/positioning";

$card-text-color: $text-color !default;
$card-bg-yellow: $ui-color-yellow !default;
$card-bg-blue: $ui-color-blue !default;

.card {
    position: relative;

    color: $card-text-color;
    display: block;

    &--yellow {
        background-color: $card-bg-yellow;
    }

    &--blue {
        background-color: $card-bg-blue;
        color: white;

        &:hover,
        &:active {
            color: white !important;
        }
    }

    &:after {
        position: absolute;

        width: 100%;
        height: 100%;
        content: " ";
        transition: opacity, box-shadow 0.3s ease;

        @include top(spacing-0);
        @include left(spacing-0);
        @include generate-shadow(shadow-1);
    }

    &:hover,
    &:active {
        color: $card-text-color;
    }

    &:hover {
        &:after {
            @include generate-shadow(shadow-2);
        }
    }

    &:active {
        &:after {
            opacity: 0;
        }
    }

    &__body {
        @include padding(spacing-4);
    }

    &__title {
        @include margin-bottom(spacing-3);
    }

    &__description {
        @include margin-bottom(spacing-0);
    }

    &__link {
        margin: 0;
    }
    &__image {
        width: 100%;
        @include margin-bottom(spacing-5);
    }

    @include breakpoint($S) {
        .card {
            &__description {
                flex: 1;
            }
        }
    }
}
