@import "../../../../styles/styleguide/color/color-variables";
@import "../../../../styles/styleguide/utils";

.storePicker-storeList {
    &__divider {
        display: block;
        width: 100%;
        border-bottom: 1px solid $ui-color-divider;

        @include smallHeightDevice {
            .store-picker-context {
                margin-bottom: 0.3rem !important;
            }
        }
    }

    &__stores {
        max-height: 400px;
        overflow-y: auto;
    }
}
