@use "sass:map";

@import "/lib/styles/styleguide/grid/_grid-factory";

.calculator-wallpaper-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: end;

    @media (max-width: #{map.get($grid-breakpoints, sm)}) {
        display: none;
    }

    span {
        margin: 0.5em 0;
    }
}
