@import "bootstrap/scss/functions"; // bootstrap functions need to be included to avoid errors
@import "bootstrap/scss/variables"; // bootstrap variables necessary for SASS

.calculator-result-field {
    display: flex;
    margin-bottom: 1em;

    &__copy {
        display: inline-block;

        .checkbox-result-field {
            input[type="checkbox"] {
                margin: 0 !important;
                position: relative;
                padding: 0 !important;
            }
        }

        &-postfix {
            font-size: $font-size-sm;
        }
    }

    &__value {
        display: flex;
        margin-left: auto;
        padding-left: 0.25em;

        &-unit {
            width: 2em;
            margin-left: 0.5em;
        }
    }
}
