.gift-card-form {
    &__submit-block {
        transition: all 0.4s ease;
        max-height: 0;
        overflow-x: hidden;

        &--active {
            transition: all 0.8s ease;
            max-height: 1000px;
        }
    }

    &__input {
        margin-left: -1.5em;
    }
}
