@use "sass:map";

@import "button-variables";
@import "button-factory";

@include _generate-button-class(
    map.get($button-default, font-weight),
    map.get($button-default, font-size),
    map.get($button-default, line-height),
    map.get($button-default, font-color),
    map.get($button-default, button-color),
    map.get($button-default, button-color--hover),
    map.get($button-default, button-color--active),
    map.get($button-default, padding-left-right),
    map.get($button-default, padding-top-bottom),
    map.get($button-default, margin-bottom),
    map.get($button-default, margin-right),
    map.get($button-default, corner-radius)
);

@each $button-class, $button-style-map in $button-size-modifiers {
    @include _generate-button-size-modifier-class(
        $button-class,
        map.get($button-style-map, font-size),
        map.get($button-style-map, line-height),
        map.get($button-style-map, padding-left-right),
        map.get($button-style-map, padding-top-bottom)
    );
}

@each $button-class, $button-style-map in $button-color-modifiers {
    @include _generate-button-color-modifier-class(
        $button-class,
        map.get($button-style-map, button-color),
        map.get($button-style-map, button-color--hover),
        map.get($button-style-map, button-color--active),
        map.get($button-style-map, font-color),
        map.get($button-style-map, font-color--hover),
        map.get($button-style-map, text-decoration--hover),
        map.get($button-style-map, border),
        map.get($button-style-map, border--hover),
        map.get($button-style-map, border--active),
        map.get($button-style-map, box-shadow),
        map.get($button-style-map, box-shadow--hover)
    );
}

@include _generate-link-class(
    map.get($link-default, font-weight),
    map.get($link-default, font-size),
    map.get($link-default, line-height),
    map.get($link-default, font-color),
    map.get($link-default, font-color--hover),
    map.get($link-default, font-color--active),
    map.get($link-default, link-icon-spacing),
    map.get($link-default, margin-bottom),
    map.get($link-default, text-decoration--hover)
);
