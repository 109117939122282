@use "sass:math";

@import "/lib/styles/styleguide/color/color-variables";

$card-height: 135px;
$details-button-size: 30px;

.store-card {
    border: 1px solid $ui-color-input-grey;
    border-radius: 0.35rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    min-height: $card-height;

    &--selected {
        background-color: $ui-color-grey;
    }

    &:hover {
        background-color: $ui-color-grey;
    }

    &__title {
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 0;
    }

    &--skeleton {
        background-color: $ui-color-grey;
        border: none;
    }
}
