@use "sass:color";
@import "color-factory";

/*
Colors and typography

This section describes base colors and typography

Styleguide 1.0
*/

/*
Main colors

$hubo-blue - Primary dark blue color
$hubo-lightblue - Used for highlights
$hubo-green  - Used for friendly errors, buttons
$hubo-red  - Secondary color for displaying errors
$hubo-orange - Defined but not used
$hubo-yellow  - Used a background color for several site widgets
$hubo-violet  - Used as visited link color

markup:
<div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>

Styleguide 1.1
*/
$primary-focus: #000c39;
$information: #2e4ebe;
$primary: #001d85;
$danger: #B90000;
$ui-color-yellow: #edd144;
$warning: #e08c00;
$secondary: #378700;
$success: #378700;
$dark: #343a40;
$ui-color-darkgrey: #1f2747;
$ui-color-violet: #8135a7;

// Interface colors where 00 is dark and 06 is light.

/*
Calculated interface colors

$ui-gray-00 - Primary dark blue color
$ui-gray-01 - Used for highlights
$ui-gray-02  - Used for friendly errors, buttons
$ui-gray-03  - Secondary color for displaying errors
$ui-gray-04 - Defined but not used
$ui-gray-05  - Used a background color for several site widgets
$ui-gray-06  - Used as visited link color

markup:
<div style="background: {$modifiers};" class="styleguide-color">{$modifiers}</div>

Styleguide 1.1.1
*/

$ui-gray-00: hsl(color.channel($primary, "hue", $space: hsl), 15%, 45%);
$ui-gray-01: hsl(color.channel($primary, "hue", $space: hsl), 15%, 55%);
$ui-gray-02: hsl(color.channel($primary, "hue", $space: hsl), 20%, 65%);
$ui-gray-03: hsl(color.channel($primary, "hue", $space: hsl), 20%, 75%);
$ui-gray-04: hsl(color.channel($primary, "hue", $space: hsl), 20%, 90%);
$ui-gray-05: hsl(color.channel($primary, "hue", $space: hsl), 40%, 95%);
$ui-gray-06: hsl(color.channel($primary, "hue", $space: hsl), 40%, 97%);

// Text
$text-color: hsl(color.channel($primary, "hue", $space: hsl), 40%, 20%);

// Links
$link-color: $information;
$link-colorhover: color.adjust($link-color, $lightness: -10%);
$link-colorfocus: color.adjust($link-color, $lightness: -10%);
$link-coloractive: color.adjust($link-color, $lightness: 10%);
$link-colorvisited: #8135a7;

$secondary-link-color: #2e4ebe;
$secondary-link-color-hover: #1f3580;

// Social media color

$twitter: #55acee;
$facebook: #3b5998;
$youtube: #cd201f;
$linkedin: #007bb6;
$google: #dd4b39;

//ui-color
$ui-color-transparent: transparent;
$ui-color-shadow-black: #000 !default;
$ui-color-primary-black: #101010 !default;
$ui-color-white: #fff !default;
$ui-color-grey: #f4f6fa;
$ui-color-blue: #001d85;
$ui-color-light-blue: #4164e6;
$ui-color-input-grey: #ced4da;
$ui-color-light-grey: #e0e3eb;
$ui-color-divider: #e8e8e8;
$ui-color-primary-black--hover: mix-color(
    $ui-color-primary-black,
    $ui-color-white,
    opacity-5
) !default;
$ui-color-primary-black--active: mix-color(
    $ui-color-primary-black,
    $ui-color-white,
    opacity-3
) !default;
$ui-color-white--hover: mix-color(
    $ui-color-white,
    $ui-color-shadow-black,
    opacity-1
) !default;
$ui-color-white--active: $ui-color-white !default;
$ui-color-grey: mix-color(
    $ui-color-white,
    $ui-color-primary-black,
    opacity-1
) !default;
$ui-color-grey-alternate: mix-color(
    $ui-color-primary-black,
    $ui-color-white,
    opacity-4
) !default;
$ui-color-disabled-font-color: #aeaeae !default;
$ui-color-alternate-black: mix-color(
    $ui-color-primary-black,
    $ui-color-white,
    opacity-1
) !default;
$ui-color-overlay-black: rgba(0, 0, 0, 0.5);
$ui-color-searchbox-focus: #b9c0db;

//identity
$identity-color-primary: #011c85 !default;
$identity-color-secondary: #d33841 !default;
$identity-color-attention: #011c85 !default;
$identity-color-attention--hover: mix-color(
    $identity-color-attention,
    $ui-color-white,
    opacity-3
) !default;
$identity-color-attention--active: mix-color(
    $identity-color-attention,
    $ui-color-primary-black,
    opacity-2
) !default;
$identity-color-promotion: #1d9d8b !default;
$identity-color-signal: #ff9514 !default;
$identity-color-success: #7bcc00 !default;
$identity-color-success--hover: mix-color(
    $identity-color-success,
    $ui-color-primary-black,
    opacity-3
) !default;
$identity-color-success--active: mix-color(
    $identity-color-success,
    $ui-color-primary-black,
    opacity-2
) !default;
$identity-color-warning: #e63535 !default;
$identity-color-warning--hover: mix-color(
    $identity-color-warning,
    $ui-color-primary-black,
    opacity-3
) !default;
$identity-color-warning--active: mix-color(
    $identity-color-warning,
    $ui-color-primary-black,
    opacity-2
) !default;
$identity-gradient-sale: linear-gradient(
    217.09deg,
    #9c64fe 0%,
    aqua 100%
) !default;

//TODO-temp
$opacities-light-ui-background-grey: color.adjust(
    $ui-color-primary-black,
    $lightness: 4%
) !default;
$opacities-light-ui-divider-grey: color.adjust(
    $ui-color-primary-black,
    $lightness: 30%
) !default;
$opacities-light-ui-copy-grey: color.adjust(
    $ui-color-primary-black,
    $lightness: 70%
) !default;
$opacities-dark-ui-background-grey: color.adjust(
    $ui-color-white,
    $lightness: -15%
) !default;
$opacities-dark-ui-divider-grey: color.adjust(
    $ui-color-white,
    $lightness: -30%
) !default;
$opacities-white-50: color.adjust($ui-color-white, $lightness: 50%) !default;
$opacities-dark-ui-copy-grey: color.adjust(
    $ui-color-white,
    $lightness: -70%
) !default;
