@use "sass:map";

@import "/lib/styles/styleguide/grid/grid-factory";
@import "/lib/styles/styleguide/spacing/spacing-factory";
@import "/lib/styles/styleguide/color/color-variables";

.card-container-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @media (min-width: #{map.get($grid-breakpoints, md)}) {
        flex-wrap: nowrap;
    }

    > div {
        flex: 25%;
        margin-bottom: 1em;
        min-width: 15em;

        @media (min-width: #{map.get($grid-breakpoints, sm)}) {
            min-width: auto;
            flex: 50%;
        }

        @media (min-width: #{map.get($grid-breakpoints, lg)}) {
            flex: 1;
        }

        .card:hover {
            text-decoration-line: none;
            background-color: $ui-color-white !important;
        }
    }
}
