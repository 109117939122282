@import "../spacing/spacing-factory";
@import "../corner-radius/corner-radius-factory";
@import "../grid/grid-factory";
@import "../opacity/opacity-factory";
@import "../color/color-variables";
@import "../layer/layer-factory";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($ui-color-primary-black, _get-opacity(opacity-5));
    cursor: pointer;
    transition: opacity 0.3s ease;
    @include z-index(modal);

    &__wrapper {
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    &__content {
        width: 100%;
        max-width: $grid-max-width;
        height: 100%;
        background-color: $ui-color-primary-black;
        cursor: auto;
    }

    &__header {
        flex-direction: column;
        display: flex;
        @include padding-top(spacing-5);
    }

    &__header,
    &__footer {
        min-height: 56px;
    }

    .icon.close {
        align-self: flex-end;
        cursor: pointer;
        fill: $ui-color-white;
        @include opacity(opacity-4);
        @include margin-right(spacing-5);
    }

    @include breakpoint($S) {
        &__content {
            width: 50%;
            height: auto;
            @include corner-radius(corner-radius-1);
        }
    }
}
