h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0.5em 0 0.75em 0;
}

h1 {
	font-weight: 400;
}

h2 {
	font-weight: 400;
}

h3 {
	font-weight: 400;
}

h4 {
	font-weight: 700;
}

h5 {
	font-weight: 400;
}

h6 {
	font-weight: 300;
}

.heading-1 {
	font-size: 1.875rem;
	font-weight: 400;
}

.heading-2 {
	font-size: 1.5rem;
	font-weight: 400;
}

.heading-3 {
	font-size: 1.25rem;
	font-weight: 400;
}

.heading-4 {
	font-size: 1.12rem;
	font-weight: 700;
}

.heading-5 {
	font-size: 1rem;
	font-weight: 400;
}

.heading-6 {
	font-size: 1rem;
	font-weight: 300;
}

.text-decoration-underline {
	text-decoration: underline;
}

.text-secondary-link-color {
	color: $secondary-link-color;
}

.x-small {
	font-size: 0.8em;
	line-height: 1;
}