@import "../../../styles/styleguide/color/color-variables";
@import "../../../styles/styleguide/grid/grid-factory";
@import "../../../styles/styleguide/spacing/spacing-factory";

.story {
    @include margin-bottom(spacing-8);

    .container {
        padding: 0;
    }

    &__text-wrapper {
        @include padding-top(spacing-7);
        .link {
            @include margin-bottom(spacing-0);
        }
    }
}
@include breakpoint($M) {
    .content-block {
        &__text-wrapper {
            align-items: center;
            display: flex;
            @include padding-top(spacing-0);
        }

        &--left {
            .content-block__image {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
}
