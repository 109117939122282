$border-sizes: (
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
);

@each $state, $data in $border-sizes {
    .border-width-#{$state} {
        border-width: $data !important;
    }
}
