@use "sass:map";

@import "../../grid/_grid-factory";

.modal {
    &-header {
        padding: 0 !important;
    }

    &-content {
        @media (min-width: #{map.get($grid-breakpoints, sm)}) {
            margin: 0 0.5rem;
            max-width: calc(100vw - 1rem);
        }
    }

    &-dialog {
        @media (min-width: #{map.get($grid-breakpoints, sm)}) {
            max-width: 650px;
            margin: 0.65rem auto;
        }

        &-centered {
            min-height: calc(100% - 1rem);

            @media (min-width: #{map.get($grid-breakpoints, sm)}) {
                align-items: center;
                margin: 0.5rem auto;
            }

            &::before {
                height: calc(100% - 1rem);
            }
        }
    }

    &-lg,
    &-xl {
        @media (min-width: #{map.get($grid-breakpoints, lg)}) {
            max-width: 800px;
        }
    }

    &-xl {
        @media (min-width: #{map.get($grid-breakpoints, xl)}) {
            max-width: 1150px;
        }
    }
}
