@import "/lib/styles/styleguide/color/color-variables";

.store-search-switch-tab {
    background-color: #fff;
    border-radius: 0.35rem;

    &__button {
        color: $ui-color-primary-black;
        box-shadow: none !important;
        border: none !important;
    }
}
