$touch-area: 24px;

.tooltip-component {
    display: inline-block;
    margin-left: 0.1em;

    &__icon {
        vertical-align: middle;
        margin-left: 0.2em;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: $touch-area;
            height: $touch-area;
            cursor: pointer;
            top: calc(50% - $touch-area / 2);
            left: calc(50% - $touch-area / 2);
        }
    }

    &__dismiss {
        position: absolute;
        right: 10px;
        top: 4px;
        font-size: 1.7em;

        &:hover {
            cursor: pointer;
        }
    }

    &__title {
        .popover & {
            margin: 0;
            padding: 15px 45px 0 15px;
            background: transparent;
            border: none;
        }
    }

    &__body {
        padding: 15px;

        > div {
            padding-right: 15px;
        }

        [data-preloaded] {
            display: block !important;
        }
    }

    &__wrapper {
        .popover {
            max-width: 600px;
        }
    }
}

[data-preloaded] {
    display: none;
}
