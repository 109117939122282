@use "sass:map";

@import "footer-component-variables";
@import "/lib/styles/styleguide/spacing/spacing";
@import "/lib/styles/styleguide/grid/grid-factory";
@import "/lib/styles/styleguide/grid/grid-variables";
@import "/lib/styles/styleguide/color/color-factory";

@mixin footer() {
    @media print {
        .footer {
            display: none;
        }
    }

    .footer {
        color: $footer--color;

        &__section {
            padding: 1.25em 0;

            &__content {
                margin: 0 1em;
            }

            &--white {
                background-color: $ui-color-white;
            }

            &--blue {
                background-color: $primary;
                color: $ui-color-white;
            }
        }

        &__disclaimer {
            text-align: center;
        }

        &__divider {
            border-top: 1px solid $ui-color-divider;
        }

        &__social-icons {
            text-align: right;

            .image-list {
                width: auto;
            }
        }

        .image-list {
            display: inline-block !important;
        }

        @media (max-width: #{map.get($grid-breakpoints, md)}) {
            &__social-icons {
                text-align: center;
                margin-top: 1rem;
            }
        }
    }
}
