@import "../list/list-factory";
@import "../button/button-variables";
@import "../button/button-factory";

@mixin rich-text-content() {
    .rich-text-content {
        ul {
            @include generate-list-base();
            @include generate-list-bullet();
        }

        ol {
            @include generate-list-base();
            @include generate-list-number();
        }
    }
}
