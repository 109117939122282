@import "/lib/styles/styleguide/color/color-variables";

.store-search-map {
    border-radius: 0.35rem;
    overflow: hidden;
    height: 100%;
    background-color: $ui-color-grey;

    &__map {
        height: 100%;
    }
}
