@use "sass:map";

@import "corner-radius-variables";

@function _get-corner-radius($corner-radius) {
    @if ($corner-radius) {
        @return map.get($corner-radii, $corner-radius);
    }
}

@mixin corner-radius($corner-radius) {
    border-radius: _get-corner-radius($corner-radius);
}

@mixin corner-radius-right($corner-radius) {
    border-top-right-radius: _get-corner-radius($corner-radius);
    border-bottom-right-radius: _get-corner-radius($corner-radius);
}

@mixin corner-radius-left($corner-radius) {
    border-top-left-radius: _get-corner-radius($corner-radius);
    border-bottom-left-radius: _get-corner-radius($corner-radius);
}

@mixin border-radius($radius, $corner-direction: "all") {
    @if $corner-direction == "all" {
        border-radius: $radius;
    } @else if $corner-direction == "top" {
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
    } @else if $corner-direction == "bottom" {
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
    } @else if $corner-direction == "left" {
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
    } @else if $corner-direction == "right" {
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
    } @else if $corner-direction == "top-left" {
        border-top-left-radius: $radius;
    } @else if $corner-direction == "top-right" {
        border-top-right-radius: $radius;
    } @else if $corner-direction == "bottom-left" {
        border-bottom-left-radius: $radius;
    } @else if $corner-direction == "bottom-right" {
        border-bottom-right-radius: $radius;
    } @else if $corner-direction == "top-left-bottom-right" {
        border-top-left-radius: $radius;
        border-bottom-right-radius: $radius;
    } @else if $corner-direction == "top-right-bottom-left" {
        border-top-right-radius: $radius;
        border-bottom-left-radius: $radius;
    } @else {
        @error "#{$corner-direction} is not a valid border-radius location";
    }
}
