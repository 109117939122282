@import "../../../styles/styleguide/spacing/_spacing-factory";
@import "../../../styles/styleguide/color/_color-variables";
@import "../../../styles/styleguide/corner-radius/_corner-radius-factory";

.promo-component {
    &__item {
        color: white;
        background-color: $identity-color-secondary;
        @include corner-radius(corner-radius-3);
    }

    &__head {
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        @include padding(spacing-3);
    }

    &__info {
        color: $identity-color-secondary;
        background-color: $ui-color-yellow;
        @include corner-radius-right(corner-radius-3);
        @include padding(spacing-3);
    }

    &__secondary {
        font-weight: 300;
        font-size: 12px;
    }

    &__period {
        flex-direction: row !important;
        gap: 0.5rem;
        justify-content: flex-end;
    }
}
