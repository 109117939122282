@use "sass:color";
@use "sass:map";

@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/font/font-variables";
@import "/lib/styles/styleguide/spacing/spacing-variables";
@import "/lib/styles/styleguide/grid/grid-factory";

.pl-component {
    &__title {
        font-size: $font-lg-size;
    }

    &__partition {
        .pl-component--link-block & {
            flex-wrap: wrap;
        }
    }
}

.pl {
    &__tileitem {
        &__block {
            width: 100%;
            height: 9.375em;
            object-fit: cover;
            object-position: center;
            position: relative;

            &__heading {
                background-color: rgba(255, 255, 255, 0.7);
                color: $ui-color-darkgrey;
                position: absolute;
                bottom: 0;
                transition: all 0.2s ease-in;

                &__title {
                    padding: 0.625em;
                }
            }
        }

        &:hover & {
            text-decoration: inherit;

            &__block {
                &__heading {
                    background-color: rgba(255, 255, 255, 0.9);
                }
            }
        }
    }

    &__listitem__wrapper {
        &--current {
            background: $ui-color-blue;
            margin-left: -0.5em;
        }

        .pl-component--link-block & {
            width: 100%;

            @media (min-width: #{map.get($grid-breakpoints, sm)}) {
                width: auto;
            }
        }

        &--link {
            .pl-component--link-block & {
                text-decoration: none;
            }

            &.btn-link:hover {
                text-decoration: none !important;

                .pl__listitem {
                    display: inline-block;
                    text-decoration: underline;
                }
            }
        }
    }

    &__listitem {
        color: inherit;
        margin: 0.25rem 1.5em 0.25rem 0;

        .pl-component--link-block & {
            font-size: 1.125em;
            border-bottom: 1px solid color.adjust($link-color, $lightness: 20%);
            display: inline;

            &:hover {
                border-color: $link-colorhover;
            }
        }

        .pl__listitem__wrapper--current & {
            color: $ui-color-white;
        }

        &--indent-1 {
            padding-left: 0;
        }

        &--indent-2 {
            padding-left: 1em;
        }

        &--indent-3 {
            padding-left: 2em;
        }

        &--indent-4 {
            padding-left: 3em;
        }
    }

    &__item {
        text-decoration: none !important;

        &:last-of-type {
            margin-bottom: 0 !important;
        }

        &:first-of-type {
            margin-top: 0 !important;
        }
    }
}
