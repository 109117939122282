@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/layer/layer";
@import "/lib/styles/styleguide/grid/grid";

.feature-switch {
    position: fixed;
    left: 0.75rem;
    bottom: 0.5rem;
    background-color: $warning;

    @include z-index(overlay);

    @include breakpoint(M) {
        left: 2rem;
        bottom: 2rem;
    }

    &--bottomControlsVisible {
        bottom: 4rem;

        @include breakpoint(M) {
            bottom: 2rem;
        }
    }
}
