@use "sass:map";

@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/grid/_grid-factory";

.searchBar {
    i {
        font-size: 1.2em;
        margin: 0 0.5em 0 0;
    }

    &__button {
        &--submit {
            @media (max-width: #{map.get($grid-breakpoints, lg)-1}) {
                color: $primary !important;
                border: 1px solid $ui-color-input-grey !important;
                border-left: none !important;
                display: flex;
                align-items: center;
                background: white !important;

                &:hover {
                    background-color: #ececec !important;
                    border-color: #e6e6e6 !important;
                    color: #212529 !important;
                }
            }
        }

        &__clear {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100%;
            padding: 0 10px;
            z-index: 5;
            border: none;
            background: none;
            color: $ui-color-darkgrey;
            display: none;

            & i {
                margin: 0;
            }
        }
    }
}

@media (min-width: #{map.get($grid-breakpoints, lg)}) {
    .searchBar__input,
    .sn-root
        .sn-suggest-form.sn-suggest-form
        .sn-suggest-input:not(
            .sn-suggest-input-hint
        ).searchBar__input.form-control {
        background-color: $ui-color-grey !important;
        border-color: $ui-color-blue !important;
    }
}
