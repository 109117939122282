@import "../../../styles/styleguide/color/_color-variables";
@import "../../../styles/styleguide/spacing/spacing-variables";
@import "../../../styles/styleguide/spacing/spacing-factory";

.orderList {
    border: 1px solid $ui-color-light-grey;

    & + & {
        @include margin-top(spacing-5);
    }
}
