@import "font-variables-unicode-ranges";

$font-base-name: "Roboto Condensed" !default;
$font-fallback-default: arial, helvetica, sans-serif !default;
$font-base-path: "https://assets.hubo.be/assets/fonts" !default;

$font-sm-size: 0.75em;
$font-base-size: 1em;
$font-lg-size: 1.25em;

$primary-font: (
    font-family: $font-base-name,
    font-file-name: robotocondensed-regular,
    font-weight: 400,
    unicode-ranges: (
        latin,
    ),
) !default;
$primary-font-bold: (
    font-family: $font-base-name,
    font-file-name: robotocondensed-bold,
    font-weight: 700,
    unicode-ranges: (
        latin,
    ),
) !default;

$primary-font-light: (
    font-family: $font-base-name,
    font-file-name: robotocondensed-light,
    font-weight: 300,
    unicode-ranges: (
        latin,
    ),
) !default;

$fontFaceMap: ($primary-font, $primary-font-bold, $primary-font-light) !default;

$localeBasedFontFamilyOverrides: () !default;

$supportedFontFormats: (
    "woff2": "woff2",
) !default;
