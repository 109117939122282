.wishlist-actionable-item {
    border: none;
    width: 100%;
    text-align: left;
    background: none;
    display: flex;

    &--new {
        padding-top: 1rem;
        border-top: 1px solid #dee2e6;
    }

    &__radio {
        margin-right: 1rem;
    }

    &__title {
        line-height: 1.25em;
        overflow: hidden;
        margin: 0 0 5px;
    }
}

.add-to-wishlist .wishlist-select-item {
    width: 100%;
}
