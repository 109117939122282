@import "/lib/styles/styleguide/grid/grid-variables";

@each $breakpoint, $breakpoint-value in $grid-breakpoints {
    @media (min-width: $breakpoint-value) {
        .d-#{$breakpoint}-none {
            display: none !important;
        }

        .d-#{$breakpoint}-block {
            display: block !important;
        }
    }
}
