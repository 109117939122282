@use "sass:map";

@import "badge-variables";
@import "badge-factory";

@include _generate-badge-class(
    map.get($badge-default, font-size),
    map.get($badge-default, line-height),
    map.get($badge-default, width),
    map.get($badge-default, display),
    map.get($badge-default, color),
    map.get($badge-default, background-color)
);

@include _generate-badge-color-modifier-class(
    map.get($badge-secondary, name),
    map.get($badge-secondary, color),
    map.get($badge-secondary, background-color)
);
