@import "/lib/styles/styleguide/spacing/_spacing-factory";
@import "/lib/styles/styleguide/grid/grid-factory";
@import "/lib/styles/styleguide/color/_color-variables";

.discount-wrapper {
    flex-direction: column;

    .discount-form-wrapper {
        max-width: 300px;
    }
}

@include breakpoint($M) {
    .discount-wrapper {
        display: flex;
    }
}

.discount-title {
    color: $ui-color-darkgrey;

    @include margin-top(spacing-4);
}

.discount-list {
    color: $primary;

    @include margin-bottom(spacing-5);

    i {
        &:hover {
            cursor: pointer;
        }
    }
}

.discount__button {
    position: relative;
    padding: 0.5rem 1rem 0.5rem 0.5rem;

    @include breakpoint(M) {
        padding: 0.5rem 0;

        &__content {
            display: inline;
            padding-right: 0;
        }

        &__tag {
            &::after,
            &::before {
                display: none;
            }
        }
    }

    &:hover {
        .discount__button__content {
            text-decoration: underline;
        }

        .discount__button__marker {
            text-decoration: none;
        }
    }

    &__content {
        position: relative;
        display: inline-block;
        padding-right: 0.5rem;
    }

    &__marker {
        position: relative;
        display: inline-block;
    }

    &__tag {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        &::after {
            content: "";
            text-align: right;
            line-height: 100%;
            padding-right: 1.5rem;
            position: absolute;
            clip-path: polygon(0% 0%, 90.1% 0%, 99.8% 50%, 90.1% 100%, 0% 100%);
            background-color: #fff;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }

        &::before {
            content: "";
            position: absolute;
            clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
            background-color: $primary;
            height: calc(100% + 2px);
            width: calc(100% + 2px);
            top: -1px;
            left: -1px;
        }
    }
}
