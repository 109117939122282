@import "../../../../styles/styleguide/color/color-variables";

.price {
    &--default {
        font-weight: bold;
        color: $danger;

        &--decimal {
            font-size: 12px;
        }
    }

    &--medium {
        font-weight: bold;
        color: $danger;
        font-size: 1.25em;
        line-height: 1.25rem;

        &--decimal {
            font-size: 0.75em;
        }
    }

    &--large {
        font-weight: bold;
        color: $danger;
        font-size: 2.5em;
        line-height: 2.5rem;

        &--decimal {
            font-size: 0.75em;
        }
    }

    &--promo {
        width: fit-content;
        position: relative;
        font-size: 0.75em;
        color: $ui-color-darkgrey;

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 45%;
            right: 0;
            border-top: 1px solid;
            border-color: inherit;
            transform: skewY(10deg);
        }
    }
}
