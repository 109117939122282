@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/variables";
@import "/lib/styles/styleguide/utils";

.flyout-menu {
    position: absolute;
    z-index: 550;
    height: clamp(20vh, 40rem, calc(100vh - #{$header-height} * 1px));
    padding-bottom: 1rem;

    &__content {
        width: 100%;
        background-color: $ui-color-white;
        padding: 1.5em;
        height: 100%;
    }

    &__col {
        color: $information;
        overflow: auto;
        height: 100%;
        @include scrollBarStyling;

        &--border {
            border-right: 1px $ui-color-grey solid;
        }
    }

    &__row {
        height: 100%;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;

        &__link {
            display: block;
            width: 100%;
            padding: 0.25em 0.75em;

            &__active {
                background-color: $ui-gray-05;
                border-left: 5px solid $danger;
                text-indent: -5px;
            }
        }
    }

    &__lightbox {
        z-index: 500;
    }
}
