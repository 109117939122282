@import "/lib/styles/styleguide/grid/grid";
@import "/lib/styles/styleguide/color/color-variables";
@import "/lib/styles/styleguide/variables";
@import "/lib/styles/styleguide/layer/layer";
@import "/lib/styles/styleguide/variables";

$search__columns__gap: 30px;
$search__group__gap: 20px;
$search__header__gap: 10px;
$search__page__padding: 1.4%;

$search__height--mobile: calc(100dvh - #{$header-height--mobile});
$search__height--desktop: calc(100dvh - #{$header-height});

.search {
    position: relative;
    display: grid;
    gap: $search__columns__gap;
    grid-template-columns: 1fr;

    &__wrapper {
        min-height: $search__height--mobile;

        @include breakpoint(L) {
            min-height: $search__height--desktop;
        }
    }

    @include breakpoint(L) {
        grid-template-columns: 2fr 8fr;
    }

    &--no-facets {
        @include breakpoint(L) {
            grid-template-columns: 1fr !important;
        }
    }

    &__loading {
        width: 100%;
        height: $search__height--mobile;

        @include breakpoint(L) {
            height: $search__height--desktop;
        }

        &__overlay {
            width: 100%;
            height: 100vh;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            z-index: 200;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $ui-color-white;
        }
    }

    .facets--product {
        min-width: 100%;

        @include breakpoint(L) {
            grid-row: 1 / 4;
        }
    }

    &__product {
        grid-column: 1 / 3;

        @include breakpoint(L) {
            grid-column: 2 / 3;
        }

        > * {
            margin-bottom: $search__header__gap;
        }

        &--no-facets {
            @include breakpoint(L) {
                grid-column: 1 !important;
            }
        }

        &__filters {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: $search__group__gap;

            @include breakpoint(S) {
                display: flex;
                grid-template-columns: none;
                gap: 0;
            }

            &--sticky {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: $search__group__gap;
                top: 0;
                left: 0;
                background-color: $ui-color-white;
                padding-left: $search__page__padding;
                padding-right: $search__page__padding;
                @include z-index(overlay);

                > div {
                    > * {
                        width: 100% !important;
                    }
                }
            }

            &__product-sort {
                grid-column: 2 / 3;

                @include breakpoint(S) {
                    grid-column: unset;
                }
            }
        }
    }

    &__content {
        grid-column: 1 / 3;

        > * {
            margin-bottom: $search__header__gap;
        }

        @include breakpoint(L) {
            &:not(&--expanded) {
                grid-column: 2 / 3;
            }
        }

        &--expanded {
            grid-row: 1 / 3;
        }
    }
}

.searchResults {
    &__noResults {
        min-height: $search__height--mobile;

        @include breakpoint(L) {
            min-height: $search__height--desktop;
        }
    }
}
