@use "sass:map";

@import "/lib/styles/styleguide/grid/_grid-factory";

.order-overview-details {
    column-count: 3;

    @media (max-width: #{map.get($grid-breakpoints, lg)}) {
        column-count: 2;
    }

    @media (max-width: #{map.get($grid-breakpoints, md)}) {
        column-count: 1;
    }

    .col-print {
        break-inside: avoid;
    }
}
