@use "sass:string";

.hide-while-loading {
    display: none;
}

.scroll-lock {
    width: 100vw;
    position: fixed;
    overflow: hidden;
}

.overflow-anchor-disabled {
    overflow-anchor: none;
}

/* stylelint-disable no-descending-specificity */
.flex-stretch-child.hrc > div,
.flex-stretch-child {
    display: flex;
    flex-direction: column;

    & > div {
        flex: 1 1 auto;
    }
}
/* stylelint-enable no-descending-specificity */

@mixin scrollBarStyling() {
    &::-webkit-scrollbar {
        width: 15px;
        background-color: #edeff7;
    }
    &::-webkit-scrollbar-thumb {
        border-left: 4px solid #fff;
        border-right: 4px solid #fff;
        background-color: #e0e3eb;
    }
    &::-webkit-scrollbar-track {
        border-left: 7px solid #fff;
        border-right: 7px solid #fff;
    }
}

@mixin smallHeightDevice() {
    @media screen and (max-height: 400px), (max-width: 768px) {
        @content;
    }
}

@function str-replace($string, $search, $replace: "") {
    $index: string.index($string, $search);

    @if $index {
        @return string.slice($string, 1, $index - 1) + $replace +
            str-replace(
                string.slice($string, $index + string.length($search)),
                $search,
                $replace
            );
    }

    @return $string;
}
