@import "../../../styles/styleguide/color/color-variables";

.hamburger {
    width: 1.75em;

    &:hover {
        cursor: pointer;
    }

    &::after,
    &::before,
    &__inner {
        background-color: $ui-color-white;
        border-radius: 1px;
        content: "";
        display: block;
        height: 2px;
        margin: 7px 0;
        transition: all 0.2s ease-in-out;
    }

    &__inner {
        &--active {
            transform: scale(0);
        }
    }

    &--active {
        &::before {
            transform: translateY(9px) rotate(135deg);
        }

        &::after {
            transform: translateY(-9px) rotate(-135deg);
        }
    }
}
