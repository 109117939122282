.wishlist-detail-component {
  &__actions{
    padding-left: 1rem;
  }

  &__actions-item  .btn:first-of-type{
      padding-left: 0 !important;
  }

  &__product-tile {
    & > div {
      height: 100%;

      & > div {
        height: 100%;
      }
    }

    @media print {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}
